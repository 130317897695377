import { CategoryManagerAction } from '../actions/types';
import { CategoryManagerConstants } from '../constants';

export const initialCategoryManagerState: STATES.CategoryManagerState = {
  isLoading: false,
  categories: [],
};

export const categoryManager = (
  state = initialCategoryManagerState,
  action: CategoryManagerAction
): STATES.CategoryManagerState => {
  switch (action.type) {
    case CategoryManagerConstants.DELETE_CATEGORY_REQUEST:
    case CategoryManagerConstants.GET_CATEGORIES_REQUEST:
    case CategoryManagerConstants.SAVE_CATEGORY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CategoryManagerConstants.SAVE_CATEGORY_SUCCESS: {
      const tempCategories = state.categories;
      const { key, icon, value } = action.payload;
      const index = tempCategories.findIndex(item => item.key === key);
      if (index > -1) {
        tempCategories[index] = { key, value, icon };
      } else {
        tempCategories.push({ key, value: key, icon });
      }

      return {
        ...state,
        isLoading: false,
        categories: tempCategories,
      };
    }
    case CategoryManagerConstants.GET_CATEGORIES_FAILURE:
    case CategoryManagerConstants.DELETE_CATEGORY_FAILURE:
    case CategoryManagerConstants.SAVE_CATEGORY_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CategoryManagerConstants.DELETE_CATEGORY_SUCCESS: {
      const tempCategories = state.categories;
      const { key } = action.payload;
      const index = tempCategories.findIndex(item => item.key === key);
      if (index > -1) {
        tempCategories.splice(index, 1);
      }
      return {
        ...state,
        categories: tempCategories,
        isLoading: false,
      };
    }
    case CategoryManagerConstants.GET_CATEGORIES_SUCCESS: {
      const { categories } = action.payload;
      return {
        ...state,
        categories,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
