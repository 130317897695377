import makeRequest from '../helpers/make-request';
import idpdata from '../assets/idp.configuration.json';
import chubbidpdata from '../assets/chubb.idp.configuration.json';
import chubbstgidpdata from '../assets/chubb.stg.idp.configuration.json';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;
const KEYCLOAK_DEFAULT_TENANT = process.env.REACT_APP_DEFAULT_TENANT;
const IDP_CONFIGURATION_FILE = process.env.REACT_APP_IDP_CONFIGURATION_FILE;

const createKeyCloakAnonymousUser = (
  anonymousUser: DTO.KeyCloakAnonymousUser
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/userlogin/keycloak/CreateAnonymousUser`;
  return makeRequest<{
    data: string;
  }>('POST', url, anonymousUser);
};

const sendOTPToAnonymousUser = (anonymousUserId: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/userlogin/keycloak/SendOTPToAnonymousUser/${anonymousUserId}`;
  return makeRequest('POST', url);
};

const generateAnonymouseUserToken = (
  anonymousUserId: string,
  linkId: string,
  code: string,
  client: string
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/userlogin/keycloak/GenerateAnonymouseUserToken/${anonymousUserId}/${linkId}/${code}/${client}`;
  return makeRequest<{ data: { targetUrl: string; access_token: string } }>(
    'POST',
    url
  );
};

const exchangeToken = (
  code: string,
  redirectUrl: string,
  codeVerifier?: string | null
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/userlogin/GenerateToken?code=${code}&redirectUrl=${redirectUrl}&codeVerifier=${codeVerifier}`;
  return makeRequest<{
    data: {
      access_token: string;
      refresh_token: string;
      username: string;
    };
  }>('GET', url);
};

const getIssuerConfiguration = async (
  issuer?: string
): Promise<DTO.IssuerConfiguration | null> => {
  let data = idpdata;
  if (IDP_CONFIGURATION_FILE === 'chubb.idp.configuration.json') {
    data = chubbidpdata;
  } else if (IDP_CONFIGURATION_FILE === 'chubb.stg.idp.configuration.json') {
    data = chubbstgidpdata;
  }

  if (data?.issuers) {
    const tenant = localStorage.getItem('Tenant') || KEYCLOAK_DEFAULT_TENANT;
    let issuerConfig: DTO.IssuerConfiguration | null = null;
    const issuerConfigTemp = data.issuers.find(
      s =>
        (issuer && s.issuer.toLowerCase() === issuer.toLowerCase()) ||
        (!issuer && tenant && s.tenant.toLowerCase() === tenant.toLowerCase())
    );

    if (issuerConfigTemp) {
      issuerConfig = issuerConfigTemp;
    }

    if (issuerConfig) {
      if (issuerConfig.verifierType === 'oidc') {
        let openIdConfigStr = localStorage.getItem(issuerConfig.verifierUri);
        if (!openIdConfigStr) {
          const response = await makeRequest<DTO.OpenIdConfiguarations>(
            'GET',
            issuerConfig.verifierUri,
            null,
            'application/json',
            null,
            null,
            false,
            true
          );

          if (response && response.payload && response.payload.issuer) {
            openIdConfigStr = JSON.stringify(response.payload);
            localStorage.setItem(issuerConfig.verifierUri, openIdConfigStr);
          }
        }

        if (openIdConfigStr) {
          const openIdConfig = JSON.parse(openIdConfigStr);
          issuerConfig.tokenUri = openIdConfig.token_endpoint;
          issuerConfig.authUri = openIdConfig.authorization_endpoint;
          issuerConfig.logoutUri = openIdConfig.end_session_endpoint;
        }
      }

      return issuerConfig;
    }
  }

  return null;
};

const getLogoutUrl = (
  issuerConfig: DTO.IssuerConfiguration | null,
  returnUrl?: string
) => {
  if (issuerConfig && issuerConfig.logoutUri) {
    const tenant = localStorage.getItem('Tenant') || KEYCLOAK_DEFAULT_TENANT;
    if (issuerConfig.authType === 'AD') {
      return `${issuerConfig.logoutUri}?redirectUrl=${window.location.origin}`;
    }
    return `${issuerConfig.logoutUri}?redirect_uri=${window.location.origin}?${
      tenant ? `tenant=${tenant}&` : ''
    }return_url=${encodeURIComponent(
      `${returnUrl ||
        window.location.href
          .replace(window.location.origin, '')
          .replace(/ /g, '@!@')}`
    )}`;
  }
  return undefined;
};

export const UserService = {
  exchangeToken,
  getLogoutUrl,
  createKeyCloakAnonymousUser,
  sendOTPToAnonymousUser,
  generateAnonymouseUserToken,
  getIssuerConfiguration,
};
