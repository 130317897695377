import React, { useRef } from 'react';
import { ConfigProvider, Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { UserActions } from '../../actions';
import { SessionTimeoutModal } from '../../components/Auth';
import { MainHeader } from '../../components/Layout';

const { Content } = Layout;

const mapStateToProps = ({ auth: { issuerConfig } }: STATES.AppState) => ({
  issuerConfig,
});

const StyledContainer = styled(Layout)`
  height: 100vh;
`;

const StyledContent = styled(Content)`
  background-color: ${({ theme }) => theme.color.aqua};
`;

const languageSelector = ({
  language: { configProviderLocale },
}: STATES.AppState) => ({
  configProviderLocale,
});

const ErrorLayout: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const { configProviderLocale } = useSelector(languageSelector);
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const { issuerConfig } = useSelector(mapStateToProps);

  return (
    <ConfigProvider
      locale={configProviderLocale}
      getPopupContainer={() => {
        return mainContainerRef.current
          ? mainContainerRef.current
          : document.body;
      }}
    >
      <StyledContainer className="main-layout">
        <MainHeader />

        <StyledContent>
          {children}
          <SessionTimeoutModal
            onTimeout={() => {
              dispatch(UserActions.logout(issuerConfig));
            }}
          />
        </StyledContent>
      </StyledContainer>
    </ConfigProvider>
  );
};

export default ErrorLayout;
