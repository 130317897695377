import { BackgroundJobsConstants } from '../constants';
import { BackgroundJobsService, FileManagerService } from '../services';
import { createAsyncThunk } from './helpers';
import { AppAction } from './types';

const getAll = () =>
  createAsyncThunk(
    async (dispatch, getState) => {
      const {
        auth: { userId, userAuth },
        backgroundJobs: { jobs },
      } = getState();

      dispatch({
        type: BackgroundJobsConstants.GET_BGJOBS_REQUEST,
      });

      const { payload } = await BackgroundJobsService.getByUserId(userId);

      payload.data.forEach(job => {
        if (job.type === 'DownloadFolder' && job.status === 'COMPLETED') {
          job.result = FileManagerService.getDownloadFileUrl(
            job.result ?? '',
            `${job.id}.zip`,
            userAuth?.id_token ?? ''
          );
        }
      });

      const newUploadCompletedJobs = payload.data
        .filter(x => x.type === 'UploadFolder' && x.status === 'COMPLETED')
        .map(job => job.id);

      const reloadProductFolderList = newUploadCompletedJobs.some(jobId => {
        const job = jobs.find(x => x.id === jobId);

        return !job || job.status !== 'COMPLETED';
      });

      dispatch({
        type: BackgroundJobsConstants.GET_BGJOBS_SUCCESS,
        payload: {
          jobs: payload.data,
          reloadProductFolderList,
        },
      });
    },
    (_, msg, dispatch) => {
      dispatch({
        type: BackgroundJobsConstants.GET_BGJOBS_FAILURE,
        payload: { error: msg },
      });
    }
  );

const cancelJob = (jobId: string) =>
  createAsyncThunk(
    async dispatch => {
      dispatch({
        type: BackgroundJobsConstants.GET_CANCEL_JOB_REQUEST,
        payload: { jobId },
      });

      await BackgroundJobsService.cancelJob(jobId);

      dispatch({
        type: BackgroundJobsConstants.GET_CANCEL_JOB_SUCCESS,
        payload: { jobId },
      });
    },
    (_, msg, dispatch) => {
      dispatch({
        type: BackgroundJobsConstants.GET_CANCEL_JOB_FAILURE,
        payload: {
          error: msg,
          jobId,
        },
      });
    }
  );

const triggerDownloadJobs = (jobs: {
  [jobId: string]: string | null;
}): AppAction => {
  Object.entries(jobs).forEach(([__, downloadUrl]) => {
    window.open(downloadUrl || '');
  });

  return {
    type: BackgroundJobsConstants.TRIGGER_DOWNLOAD_GBJOBS,
    payload: { jobs },
  };
};

export const BackgroundJobsActions = {
  getAll,
  cancelJob,
  triggerDownloadJobs,
};
