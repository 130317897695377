import React, { NamedExoticComponent, memo } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

interface FilterCardProps {
  total: number;
  className?: string;
  text: string;
  isSelected: boolean;
  filterBy: string;
  filterValue: string | boolean;
  onSelect: (filterBy: string, filterValue: string | boolean) => void;
}

const Container = styled(Button)`
  && {
    white-space: pre-wrap;
    padding: 0;
    background: ${({ theme }) => theme.color.white};
    border: thin solid ${({ theme }) => theme.color.gray5};
    color: ${({ theme }) => theme.color.dark};
    border-radius: 4px !important;
    font-weight: normal;
    text-align: center;
    font-size: 16px;
    width: auto;
    height: auto;

    > div {
      width: 120px;
      height: 56px;
      display: flex;
      flex-direction: column;
    }

    :hover,
    :active,
    :focus,
    &.hightlight {
      background: ${({ theme }) => theme.color.aqua};
      color: ${({ theme }) => theme.color.white};
    }
  }
`;

const Total = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  font-size: 20px;
`;

const Text = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  padding: 0 5px 10px 5px;
  font-size: 14px;
`;

const FilterCard: NamedExoticComponent<FilterCardProps> = memo(
  ({ text, total, className, onSelect, isSelected, filterBy, filterValue }) => {
    return (
      <Container
        className={`${className || ''} ${isSelected ? 'hightlight' : ''}`}
        onClick={() => onSelect(filterBy, filterValue)}
      >
        <div>
          <Total>{total}</Total>
          <Text>
            <FormattedMessage id={text} />
          </Text>
        </div>
      </Container>
    );
  }
);

export { FilterCard };
