import makeRequest from '../helpers/make-request';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;

const getUserGroups = (data: DTO.GetUserGroupRulesRequest) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/UserGroupRule/List`;

  return makeRequest<DTO.GetUserGroupRulesResponse>('POST', url, data);
};

const deleteUserGroup = (userGroupName: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/UserGroupRule/Delete/${userGroupName}`;

  return makeRequest('DELETE', url);
};

const saveUserGroupInfo = (userGroup: DTO.UserGroupRule) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/UserGroupRule/SaveUserGroup`;

  return makeRequest('POST', url, userGroup);
};

const getUserGroup = (userGroupName: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/UserGroupRule/Get/${userGroupName}`;

  return makeRequest<{ data: DTO.UserGroupRule }>('GET', url);
};

const saveUserGroup = (data: DTO.UserGroupRule) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/UserGroupRule/SaveUserGroupSetting`;

  return makeRequest<{ data: DTO.UserGroupRule }>('POST', url, data);
};

const downloadUserGroups = (
  data: DTO.GetUserGroupRulesRequest,
  fileName: string
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/UserGroupRule/Export?fileName=${fileName}`;

  return makeRequest<{ blob: Blob }>(
    'POST',
    url,
    data,
    undefined,
    undefined,
    undefined,
    true
  );
};

export const ClientManagerService = {
  getUserGroups,
  getUserGroup,
  saveUserGroup,
  deleteUserGroup,
  saveUserGroupInfo,
  downloadUserGroups,
};
