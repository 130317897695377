import queryString from 'query-string';
import uploadRequest from '../helpers/upload-request';
import makeRequest from '../helpers/make-request';
import httpClient from '../helpers/http-client';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;

const getProductLookUp = () => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/GetLookupData`;

  return makeRequest<DTO.GetLookupDataResponse>('GET', url);
};

const getProducts = (request: DTO.GetProductsRequest) => {
  const {
    page = 1,
    pageSize = 11,
    sortBy = 'updated',
    sort = 'desc',
    searchText = '',
    filterStatus = '',
    filterCategory = '',
  } = request;

  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/list?`;

  const search: { field: string; value: string }[] = [];

  if (searchText) {
    search.push({ field: 'productName_co', value: searchText });
  }

  if (filterStatus) {
    search.push({ field: 'status1_co', value: filterStatus });
  }

  if (filterCategory) {
    search.push({ field: 'name1_co', value: filterCategory });
  }

  search.push({ field: 'isstarred', value: 'true' });

  return makeRequest<DTO.GetProductsResponse>('POST', url, {
    page,
    pageSize,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
    search,
  });
};

const getSharedProducts = (request: DTO.GetProductsRequest) => {
  const {
    page = 1,
    pageSize = 11,
    sortBy = 'updated',
    sort = 'desc',
    searchText = '',
    filterStatus = '',
    filterCategory = '',
  } = request;

  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/list?`;

  const search: { field: string; value: string }[] = [];

  if (searchText) {
    search.push({ field: 'productName_co', value: searchText });
  }

  if (filterStatus) {
    search.push({ field: 'status1_co', value: filterStatus });
  }

  if (filterCategory) {
    search.push({ field: 'name1_co', value: filterCategory });
  }

  // if (filterFavorite) {
  //   search.push({ field: 'isstarred', value: filterFavorite.toString() });
  // }

  return makeRequest<DTO.GetProductsResponse>('POST', url, {
    page,
    pageSize,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
    search,
  });
};

const createProducts = (product: DTO.CreateProductRequest) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/create`;

  const formData = new FormData();

  formData.append('Name', product.name);
  formData.append('Category', product.category);
  formData.append('Description', product.description);
  formData.append('LaunchDate', product.launchDate);
  formData.append('StartDate', product.startDate);
  formData.append('Status', 'Design');

  if (product.sharedWith) {
    formData.append('SharedWithUserGroups', product.sharedWith);
  }

  if (product.coverImage) {
    formData.append('CoverImage', product.coverImage);
  }

  return makeRequest('POST', url, formData);
};

const cloneProduct = (product: DTO.CloneProductRequest) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${product.originalName}/clone`;
  const formData = new FormData();

  formData.append('name', product.name);
  formData.append('category', product.category);
  formData.append('description', product.description);
  formData.append('launchDate', product.launchDate);
  formData.append('startDate', product.startDate);

  if (product.sharedWith) {
    formData.append('sharedWithUserGroups', product.sharedWith);
  }

  if (product.coverImage) {
    formData.append('coverImage', product.coverImage);
  }

  return makeRequest<DTO.CloneProductResponse>('POST', url, formData);
};

const updateProduct = (
  productId: string,
  product: DTO.UpdateProductRequest
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/update/${productId}`;

  return makeRequest('POST', url, product);
};

const uploadCoverImage = (
  productId: string,
  file: File | Blob,
  onUploadProgress: (percent: number) => void
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/UploadCoverImage`;
  const formData = new FormData();

  formData.append('id', productId);
  formData.append('coverImage', file);

  return uploadRequest('POST', url, formData, onUploadProgress);
};

const getCoverImage = (productName: string, fileName: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/GetImage/${fileName}/${productName}`;

  return makeRequest<{ blob: Blob }>(
    'GET',
    url,
    undefined,
    'application/octet-stream'
  );
};

const getProductDetails = (
  productName: string,
  data: DTO.GetProductDetailsRequest = {}
) => {
  let url = `${EXCELENGINE_DOMAIN}/api/v1/product/GetProduct/${productName}?`;

  const { noOfCalculationEngines = 5 } = data;

  url += queryString.stringify({
    noOfCalculationEngines,
  });

  return makeRequest<DTO.GetProductDetailsResponse>('GET', url);
};

const setFavoriteProduct = (productId: string, isFavorite: boolean) =>
  updateProduct(productId, { isStarred: isFavorite });

const getProductDocs = (
  page: number,
  pageSize: number,
  productName: string,
  documentType: string,
  searchTerm: string
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/documents/List/${documentType}`;
  const search: { field: string; value: string }[] = [];

  if (searchTerm) {
    search.push({ field: 'name1_co', value: searchTerm });
  }
  return makeRequest<DTO.GetProductDocsResponse>('POST', url, {
    page,
    pageSize,
    sort: '-created',
    search,
  });
};

const deleteDocs = (
  productName: string,
  fileName: string,
  documentType: string
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/documents/Delete/${fileName}/${documentType}`;

  return makeRequest('DELETE', url);
};

const uploadProductDoc = (
  documentType: string,
  productName: string,
  productDocument: File,
  metadata: object,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/documents/create`;
  const formData = new FormData();

  formData.append('ProductDocument', productDocument);
  formData.append('DocumentType', documentType);
  formData.append('Metadata', JSON.stringify(metadata));

  return uploadRequest<DTO.UploadProductDocResponse>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const getDocumentDownloadUrl = (
  request: DTO.DownloadDocumentRequest,
  documentType: string,
  token: string
) => {
  const { productName, fileName } = request;

  return `${EXCELENGINE_DOMAIN}/api/v1/Product/${productName}/documents/download/${fileName}/${documentType}?token=${encodeURIComponent(
    token
  )}`;
};

const getRecentChanges = (
  productName: string,
  data: DTO.GetRecentChangeRequest
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/useraction/list/${productName}`;

  if (!data.NextPageToken) {
    delete data.NextPageToken;
  }

  return makeRequest<DTO.GetRecentChangeResponse>('POST', url, data);
};

const getHtmlFileUrl = (
  productName: string,
  serviceName: string,
  token: string,
  type: string
) => {
  return `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/documents/GetContent/${serviceName}/${type}?token=${token}`;
};

const uploadProductZip = (
  file: File,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/ValidateProductZip`;

  const formData = new FormData();

  formData.append('file', file);

  return uploadRequest<{ data: DTO.ValidateProductZipResponse }>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const createProductByZipFile = (data: DTO.CreateProductByZipRequest) =>
  httpClient({
    method: 'POST',
    path: '/api/v1/product/UploadProduct',
    body: data,
  });

const markDocFavorite = (
  productName: string,
  fileName: string,
  documentType: string,
  favorite: boolean
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/documents/update`;

  return makeRequest<unknown>('PUT', url, {
    fileName,
    documentType,
    isStared: favorite,
  });
};

const createSection = (
  productName: string,
  sectionName: string,
  position: number,
  fileTypes: string[]
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/sections/create/${sectionName}/${position}`;

  return makeRequest<unknown>('POST', url, fileTypes);
};

const updateSection = (
  productName: string,
  oldSectionName: string,
  newSectionName: string,
  position: number,
  fileTypes: string[]
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/sections/update/${oldSectionName}/${newSectionName}/${position}`;

  return makeRequest<unknown>('PUT', url, fileTypes);
};

const updateDocumentMetadata = (
  productName: string,
  fileName: string,
  documentType: string,
  metadata: object
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/documents/UpdateDocumentMetadata/${fileName}/${documentType}`;

  return makeRequest<unknown>('PUT', url, metadata);
};

const getDocumentContent = (
  productName: string,
  fileName: string,
  documentType: string
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/documents/GetDocumentContent/${fileName}/${documentType}`;

  return makeRequest<DTO.GetDocumentContentResponse>('GET', url);
};

const deleteSection = (productName: string, sectionName: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/sections/delete/${sectionName}`;

  return makeRequest<unknown>('DELETE', url);
};

const getSwaggerDownloadUrl = (
  productName: string,
  fileName: string,
  documentType: string,
  token: string
) => {
  return `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/documents/DownloadSwagger/${fileName}/${documentType}?token=${encodeURIComponent(
    token
  )}`;
};

const getCurlDownloadUrl = (
  productName: string,
  fileName: string,
  documentType: string,
  token: string
) => {
  return `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/documents/DownloadCURL/${fileName}/${documentType}?token=${encodeURIComponent(
    token
  )}`;
};

export const ProductService = {
  getProducts,
  getSharedProducts,
  createProducts,
  updateProduct,
  uploadCoverImage,
  getCoverImage,
  getProductLookUp,
  setFavoriteProduct,
  getProductDetails,
  getProductDocs,
  uploadProductDoc,
  cloneProduct,
  getDocumentDownloadUrl,
  deleteDocs,
  getRecentChanges,
  getHtmlFileUrl,
  uploadProductZip,
  createProductByZipFile,
  markDocFavorite,
  createSection,
  updateSection,
  deleteSection,
  getSwaggerDownloadUrl,
  getCurlDownloadUrl,
  updateDocumentMetadata,
  getDocumentContent,
};
