import { isEqual } from 'lodash-es';
import { EngineAction, MasterReferenceAction } from '../actions/types';
import { MasterReferenceConstants } from '../constants';
import { arrayRemoveFirst } from '../helpers';

export const initialState: STATES.ReferenceState = {
  isLoading: false,
  documents: [],
  totalPages: 0,
  total: 0,
  docRequest: {
    page: 1,
    pageSize: 20,
    searchText: '',
    sort: 'desc',
    sortBy: 'updated',
  },
  uploadProgress: 0,
  error: null,
  xhrRef: null,
  isUploading: false,
  refreshTrigger: 0,
  selectedDocument: null,
  unsyncedEngineReferences: null,
  selectedFileName: '',
  isLoadingMasterAPI: false,
  masterAPIFile: null,
  isReferenceSyncing: false,
  syncedAllEngines: false,
  referenceRangeValue: null,
  productTutorialMismatches: null,
  checkingProductTutorialMismatches: false,
  productTutorials: [],
};

export const masterReferences = (
  state = initialState,
  action: MasterReferenceAction | EngineAction
): STATES.ReferenceState => {
  switch (action.type) {
    case MasterReferenceConstants.GET_MASTER_REFS_REQUEST: {
      const { docRequest } = action.payload;

      return {
        ...state,
        docRequest,
        isLoading: true,
      };
    }
    case MasterReferenceConstants.GET_MASTER_REFS_SUCCESS: {
      const { documents, total, docRequest } = action.payload;

      if (!isEqual(docRequest, state.docRequest)) {
        return state;
      }

      return {
        ...state,
        isLoading: false,
        documents,
        total,
        totalPages:
          Math.floor(total / state.docRequest.pageSize) +
          (total % state.docRequest.pageSize ? 1 : 0),
      };
    }
    case MasterReferenceConstants.GET_MASTER_REFS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case MasterReferenceConstants.GET_MASTER_API_REQUEST: {
      return {
        ...state,
        isLoadingMasterAPI: true,
      };
    }
    case MasterReferenceConstants.GET_MASTER_API_SUCCESS: {
      const { file } = action.payload;

      return {
        ...state,
        isLoadingMasterAPI: false,
        masterAPIFile: file,
      };
    }
    case MasterReferenceConstants.GET_MASTER_API_FAILURE: {
      return {
        ...state,
        isLoadingMasterAPI: false,
      };
    }
    case MasterReferenceConstants.MASTER_REF_UPLOAD_START: {
      return {
        ...state,
        isUploading: true,
        uploadProgress: 5,
      };
    }
    case MasterReferenceConstants.MASTER_REF_UPLOAD_XHR_REF: {
      const { xhrRef } = action.payload;
      return {
        ...state,
        xhrRef,
      };
    }
    case MasterReferenceConstants.MASTER_REF_UPLOAD_PROGRESS: {
      const { uploadProgress } = action.payload;

      return {
        ...state,
        uploadProgress,
      };
    }
    case MasterReferenceConstants.MASTER_REF_UPLOAD_SUCCESS: {
      const { engineReferences, fileName } = action.payload;

      return {
        ...state,
        isUploading: false,
        refreshTrigger: state.refreshTrigger + 1,
        uploadProgress: 100,
        unsyncedEngineReferences: engineReferences,
        selectedFileName: fileName,
      };
    }
    case MasterReferenceConstants.MASTER_REF_UPLOAD_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isUploading: false,
        error,
      };
    }
    case MasterReferenceConstants.MASTER_REF_UPLOAD_RESET: {
      return {
        ...state,
        unsyncedEngineReferences: null,
        uploadProgress: 0,
        error: null,
      };
    }
    case MasterReferenceConstants.MASTER_REF_SYNC_RESET: {
      return {
        ...state,
        selectedDocument: null,
        isLoading: false,
        isReferenceSyncing: false,
        productTutorials: [],
        productTutorialMismatches: null,
      };
    }
    case MasterReferenceConstants.SELECT_MASTER_REF: {
      const { document } = action.payload;

      return {
        ...state,
        selectedDocument: document,
      };
    }
    case MasterReferenceConstants.DELETE_MASTER_REF_SUCCESS: {
      const { id } = action.payload;

      if (state.masterAPIFile && state.masterAPIFile.id === id) {
        return {
          ...state,
          masterAPIFile: null,
        };
      }

      return {
        ...state,
        documents: arrayRemoveFirst(state.documents, doc => doc.id === id),
      };
    }
    case MasterReferenceConstants.GET_MASTER_REF_DETAIL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case MasterReferenceConstants.GET_MASTER_REF_DETAIL_SUCCESS: {
      const { document } = action.payload;

      return {
        ...state,
        selectedDocument: document,
        isLoading: false,
      };
    }
    case MasterReferenceConstants.GET_MASTER_REF_DETAIL_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case MasterReferenceConstants.UPDATE_MASTER_REF_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case MasterReferenceConstants.UPDATE_MASTER_REF_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshTrigger: state.refreshTrigger + 1,
      };
    }
    case MasterReferenceConstants.UPDATE_MASTER_REF_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case MasterReferenceConstants.GET_DOWNSTREAM_ENGINE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        syncedAllEngines: false,
      };
    }
    case MasterReferenceConstants.GET_DOWNSTREAM_ENGINE_SUCCESS: {
      const { documentId, downStreamEngines } = action.payload;
      if (state.selectedDocument && state.selectedDocument.id === documentId) {
        return {
          ...state,
          isLoading: false,
          selectedDocument: { ...state.selectedDocument, downStreamEngines },
        };
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case MasterReferenceConstants.GET_DOWNSTREAM_ENGINE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case MasterReferenceConstants.MASTER_REF_SYNC_SUCCESS: {
      if (state.selectedDocument) {
        return {
          ...state,
          isLoading: false,
          isReferenceSyncing: false,
          selectedDocument: {
            ...state.selectedDocument,
            downStreamEngines: null,
          },
        };
      }
      return {
        ...state,
        isLoading: false,
        isReferenceSyncing: false,
      };
    }
    case MasterReferenceConstants.MASTER_REF_SYNC_REQUEST: {
      return {
        ...state,
        isReferenceSyncing: true,
      };
    }
    case MasterReferenceConstants.MASTER_REF_SYNC_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isReferenceSyncing: false,
      };
    }
    case MasterReferenceConstants.MASTER_REF_SYNC_ALL_REQUEST: {
      return {
        ...state,
        isLoading: true,
        syncedAllEngines: false,
      };
    }
    case MasterReferenceConstants.MASTER_REF_SYNC_ALL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        syncedAllEngines: true,
      };
    }
    case MasterReferenceConstants.MASTER_REF_SYNC_ALL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        syncedAllEngines: false,
      };
    }
    case MasterReferenceConstants.READ_MASTER_REFERENCE_RANGE_REQUEST:
    case MasterReferenceConstants.WRITE_MASTER_REFERENCE_RANGE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case MasterReferenceConstants.READ_MASTER_REFERENCE_RANGE_SUCCESS: {
      const { referenceRangeValue } = action.payload;
      return {
        ...state,
        isLoading: false,
        referenceRangeValue,
      };
    }
    case MasterReferenceConstants.CLEAR_MASTER_REFERENCE_RANGE:
    case MasterReferenceConstants.WRITE_MASTER_REFERENCE_RANGE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        referenceRangeValue: null,
      };
    }
    case MasterReferenceConstants.READ_MASTER_REFERENCE_RANGE_FAILURE:
    case MasterReferenceConstants.WRITE_MASTER_REFERENCE_RANGE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case MasterReferenceConstants.VALIDATE_PRODUCT_TURORIALS_REQUEST: {
      return {
        ...state,
        isLoadingMasterAPI: true,
        checkingProductTutorialMismatches: true,
        productTutorialMismatches: null,
      };
    }
    case MasterReferenceConstants.GET_PRODUCT_TURORIALS_REQUEST: {
      return {
        ...state,
        isLoadingMasterAPI: true,
        productTutorials: [],
      };
    }
    case MasterReferenceConstants.GET_PRODUCT_TURORIALS_SUCCESS: {
      const { productTutorials } = action.payload;
      return {
        ...state,
        isLoadingMasterAPI: false,
        productTutorials,
      };
    }
    case MasterReferenceConstants.VALIDATE_PRODUCT_TURORIALS_SUCCESS: {
      const { productTutorialMismatches } = action.payload;
      return {
        ...state,
        isLoadingMasterAPI: false,
        productTutorialMismatches,
        checkingProductTutorialMismatches: false,
      };
    }
    case MasterReferenceConstants.VALIDATE_PRODUCT_TURORIALS_FAILURE: {
      return {
        ...state,
        isLoadingMasterAPI: false,
        checkingProductTutorialMismatches: false,
      };
    }
    case MasterReferenceConstants.GET_PRODUCT_TURORIALS_FAILURE: {
      return {
        ...state,
        isLoadingMasterAPI: false,
      };
    }
    default:
      return state;
  }
};
