import { ReactComponent as ProductStatusDesign } from './ic_product_st_design.svg';
import { ReactComponent as ProductStatusArchive } from './ic_product_st_archive.svg';
import { ReactComponent as ProductStatusProduction } from './ic_product_st_production.svg';
import { ReactComponent as SideMenuCalculations } from './ic_sidemenu_calculations.svg';
import { ReactComponent as SideMenuDevelopment } from './ic_sidemenu_development.svg';
import { ReactComponent as SideMenuProduct } from './ic_sidemenu_product.svg';
import { ReactComponent as SideMenuTesting } from './ic_sidemenu_testing.svg';
import { ReactComponent as SideMenuKanban } from './ic_sidemenu_kanban.svg';
import { ReactComponent as SideMenuCatalogue } from './ic_sidemenu_catalogue.svg';
import { ReactComponent as SideMenuPerformance } from './ic_sidemenu_performance.svg';
import { ReactComponent as ModalOpen } from './ic_modal_open.svg';
import { ReactComponent as ModalClose } from './ic_modal_close.svg';
import { ReactComponent as Refresh } from './ic_refresh.svg';
import { ReactComponent as ExcelFile } from './ic_excel.svg';
import { ReactComponent as PdfFile } from './ic_pdf.svg';
import { ReactComponent as WordFile } from './ic_word.svg';
import { ReactComponent as EngineFile } from './ic_engine.svg';
import { ReactComponent as ImageFile } from './ic_image.svg';
import { ReactComponent as TutorialFile } from './ic_tutorial.svg';
import { ReactComponent as HtmlFile } from './ic_html.svg';
import { ReactComponent as PowerPointFile } from './ic_powerpoint.svg';
import { ReactComponent as ModalWarn } from './ic_modal_warn.svg';
import { ReactComponent as Clone } from './ic_clone.svg';
import { ReactComponent as ProductStatusProductionOutlined } from './ic_status_production_outlined.svg';
import { ReactComponent as ProductStatusArchiveOutlined } from './ic_status_archive_outlined.svg';
import { ReactComponent as ProductStatusDesignOutlined } from './ic_status_design_outlined.svg';
import { ReactComponent as FolderOpen } from './ic_folder_open.svg';
import { ReactComponent as FolderDefault } from './ic_folder_default.svg';
import { ReactComponent as Shared } from './ic_shared.svg';
import { ReactComponent as Language } from './ic_language.svg';
import { ReactComponent as Logout } from './ic_logout.svg';
import { ReactComponent as Sync } from './ic_sync.svg';
import { ReactComponent as Desktop } from './ic_desktop.svg';
import { ReactComponent as Tablet } from './ic_tablet.svg';
import { ReactComponent as Phone } from './ic_phone.svg';
import { ReactComponent as Edit } from './ic_edit.svg';
import { ReactComponent as PlusColumn } from './ic_plus_column.svg';
import { ReactComponent as File } from './ic_file.svg';
import { ReactComponent as DoubleLeftArrow } from './ic_double_left_arrow.svg';
import { ReactComponent as Cockpit } from './ic_cockpit.svg';
import { ReactComponent as Tester } from './ic_tester.svg';
import { ReactComponent as Alian } from './ic_alian.svg';

export default {
  ProductStatusDesign,
  ProductStatusArchive,
  ProductStatusProduction,
  SideMenuCalculations,
  SideMenuDevelopment,
  SideMenuProduct,
  SideMenuTesting,
  SideMenuKanban,
  SideMenuPerformance,
  SideMenuCatalogue,
  ModalOpen,
  ModalClose,
  Refresh,
  ExcelFile,
  PdfFile,
  WordFile,
  PowerPointFile,
  EngineFile,
  TutorialFile,
  HtmlFile,
  ImageFile,
  ModalWarn,
  Clone,
  ProductStatusProductionOutlined,
  ProductStatusArchiveOutlined,
  ProductStatusDesignOutlined,
  FolderOpen,
  FolderDefault,
  Shared,
  Language,
  Logout,
  Sync,
  Desktop,
  Tablet,
  Phone,
  Edit,
  PlusColumn,
  File,
  DoubleLeftArrow,
  Cockpit,
  Tester,
  Alian,
};
