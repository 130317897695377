import { SystemThunkAction } from './types';
import { SystemConstants } from '../constants';
import { AlertActions } from './alert.actions';
import { ApiError } from '../helpers';
import { SystemService } from '../services/system.services';

const getReleaseDetails = (): SystemThunkAction => async dispatch => {
  try {
    dispatch({
      type: SystemConstants.GET_RELEASE_DETAILS_REQUEST,
    });

    const { payload, status } = await SystemService.getReleaseDetails();

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: SystemConstants.GET_RELEASE_DETAILS_SUCCESS,
      payload: { releaseDetails: payload.data },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: SystemConstants.GET_RELEASE_DETAILS_FAILURE,
      payload: { error: msg },
    });
  }
};

const getLookupData = (): SystemThunkAction => async (dispatch, getState) => {
  try {
    const {
      system: { isLoadingLookupData },
    } = getState();

    if (isLoadingLookupData) {
      return;
    }

    dispatch({
      type: SystemConstants.GET_LOOKUP_DATA_REQUEST,
    });

    const { payload, status } = await SystemService.getLookupData();

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: SystemConstants.GET_LOOKUP_DATA_SUCCESS,
      payload: { lookupData: payload.data },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: SystemConstants.GET_LOOKUP_DATA_FAILURE,
      payload: { error: msg },
    });
  }
};

const getProductDocumentMetadata = (): SystemThunkAction => async (
  dispatch,
  getState
) => {
  try {
    const {
      system: { isLoadingLookupData },
    } = getState();

    if (isLoadingLookupData) {
      return;
    }

    dispatch({
      type: SystemConstants.GET_PRODUCT_DOCUMENT_METADATA_REQUEST,
    });

    const {
      payload,
      status,
    } = await SystemService.getProductDocumentMetadata();

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: SystemConstants.GET_PRODUCT_DOCUMENT_METADATA_SUCCESS,
      payload: { metadata: payload.data },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: SystemConstants.GET_PRODUCT_DOCUMENT_METADATA_FAILURE,
      payload: { error: msg },
    });
  }
};

const getDiagnoseDetails = (): SystemThunkAction => async dispatch => {
  try {
    dispatch({
      type: SystemConstants.GET_DIAGNOSE_DETAILS_REQUEST,
    });

    const { payload, status } = await SystemService.getDiagnoseDetails();
    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    const { data } = payload;

    dispatch({
      type: SystemConstants.GET_DIAGNOSE_DETAILS_SUCCESS,
      payload: { diagnoseDetails: data },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: SystemConstants.GET_DIAGNOSE_DETAILS_FAILURE,
      payload: { error: msg },
    });
  }
};

const clearCacheMemory = (): SystemThunkAction => async dispatch => {
  try {
    dispatch({
      type: SystemConstants.CLEAR_CACHE_MEMORY_REQUEST,
    });

    const { payload, status } = await SystemService.clearCacheMemory();

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    const { data } = payload;

    dispatch({
      type: SystemConstants.CLEAR_CACHE_MEMORY_SUCCESS,
      payload: { diagnoseDetails: data },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: SystemConstants.CLEAR_CACHE_MEMORY_FAILURE,
      payload: { error: msg },
    });
  }
};

const clearApplicationMemory = (): SystemThunkAction => async dispatch => {
  try {
    dispatch({
      type: SystemConstants.CLEAR_APPLICATION_MEMORY_REQUEEST,
    });

    const { payload, status } = await SystemService.clearApplicationMemory();

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    const { data } = payload;

    dispatch({
      type: SystemConstants.CLEAR_APPLICATION_MEMORY_SUCCESS,
      payload: { diagnoseDetails: data },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: SystemConstants.CLEAR_APPLICATION_MEMORY_FAILURE,
      payload: { error: msg },
    });
  }
};

export const SystemActions = {
  getReleaseDetails,
  getDiagnoseDetails,
  getLookupData,
  clearCacheMemory,
  clearApplicationMemory,
  getProductDocumentMetadata,
};
