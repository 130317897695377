const MainDefaultTheme = {
  appName: 'Product Factory',
  logo: '/assets/img/logo.svg',
  logoOnWhite: '/assets/img/logo-on-white.svg',
  logoOnWhiteAnimation: '/assets/img/logo-on-white-animation.gif',
  logoOnWhiteWithoutText: '/assets/img/logo-on-white-without-text.svg',
  logoOnColoured: '/assets/img/logo-on-coloured.svg',
  logoOnColouredWithoutText: '/assets/img/logo-on-coloured-without-text.svg',
  fontLink: '',
  fontFaces: [
    {
      family: 'GT Walsheim',
      weight: '300',
      style: 'nomal',
      fileName: 'GT-Walsheim-Light',
    },
    {
      family: 'GT Walsheim',
      weight: 'normal',
      style: 'nomal',
      fileName: 'GT-Walsheim-Regular',
    },
    {
      family: 'GT Walsheim',
      weight: '500',
      style: 'nomal',
      fileName: 'GT-Walsheim-Medium',
    },
    {
      family: 'GT Walsheim',
      weight: 'bold',
      style: 'nomal',
      fileName: 'GT-Walsheim-Bold',
    },
  ],
  vars: {
    textColor: '#201A3D',
    primaryColor: '#24C4CC',
    secondaryColor: '#201A3D',
    dangerColor: '#DC5034',
    fontFamily: 'GT Walsheim',
    shadow: '#888',
    white: '#ffffff',
    border: '#CCCCCC',
    tabContentBg: '#f4f4f3',
    switchBg: '#f4f4f3',
    expiredPasswordBg: '#E17FC3',
    formbuilderContentBg: '#FBFBFA',
    folderCardSelectedBg: '#EAFAFA',
    editFileMetaInputBg: '#F4F4F4',
    fileUploadModalBg: '#D3F3F5',
    mainHeaderBorderColor: '#003363',
  },
  color: {
    white: '#ffffff',
    black: '#000000',
    pearl: '#ECECEB',
    dark: '#201A3D',
    light: '#757286',
    gray1: '#333333',
    gray4: '#d9d9d9',
    gray3: '#828282',
    gray6: '#F2F2F2',
    gray2: '#A6A3B1',
    gray5: '#E0E0E0',
    gray91: '#E8E8E8',
    aqua: '#24C4CC',
    lightAqua: '#D3F3F5',
    // tint(..., 60%)
    aqua40: '#A7E7EB',
    azure: '#3B8CFF',
    azure2: '#A0C6F6',
    purple: '#9C43FF',
    lightPurple: '#79768B',
    red: '#DC5034',
    lightRed: '#f5222d',
  },
};

export default MainDefaultTheme;
