import React from 'react';
import { useRouteMatch, generatePath, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { colorHexToRgba } from '../../helpers';

export interface BreadcrumbRoutesConfig {
  [routePath: string]:
    | string
    | ((params: { [name: string]: string }) => string);
}

interface BreadcrumbRoutesProps {
  routes?: BreadcrumbRoutesConfig;
  fixedRoutes?: { to: string; name: string }[];
}

const Container = styled.div`
  && {
    display: flex;
    padding-top: 16px;
    padding-bottom: 5px;
    align-items: center;
  }
`;

const StyledBreadcrumb = styled(Breadcrumb).attrs({
  'data-testid': 'breadcrumbs-container',
})`
  && {
    color: ${({ theme }) => colorHexToRgba(theme.color.dark, 0.43)};
    font-weight: normal;

    a,
    > span:last-child a {
      color: ${({ theme }) => colorHexToRgba(theme.color.black, 0.43)};
    }
  }
`;

const BreadcrumbRoutes: React.FC<BreadcrumbRoutesProps> = ({
  routes = {},
  fixedRoutes = [],
}) => {
  const match = useRouteMatch<{ [name: string]: string }>({
    path: Object.keys(routes),
    exact: true,
  });
  const matches: { to: string; name: string }[] = [];

  if (match) {
    const paths = match.path.split(/\//g);

    let lastKey = '';
    let name = '';
    let to = '';

    paths.forEach(path => {
      lastKey += path ? `/${path}` : path;

      const route = routes[lastKey];

      name = typeof route === 'function' ? route(match.params) : route;
      to = lastKey;

      if (name) {
        if (name.startsWith(':')) {
          name = match.params[name.substring(1)] || name;
        }

        to = generatePath(to, match.params);

        matches.push({ to, name });
      }
    });
  }

  return (
    <Container>
      <StyledBreadcrumb separator="/">
        {(matches.length > 0 ? matches : fixedRoutes).map(({ to, name }) => (
          <Breadcrumb.Item key={to}>
            <Link to={to}>
              <FormattedMessage id={name} />
            </Link>
          </Breadcrumb.Item>
        ))}
      </StyledBreadcrumb>
    </Container>
  );
};

export { BreadcrumbRoutes };
