class ApiError extends Error {
  codes: string[];

  message: string;

  constructor(response: {
    error_code?: string;
    error_codes?: string[];
    errorCode?: string;
    message?: string;
    Message?: string;
  }) {
    super(response.errorCode || response.error_code || '');

    const { error_code, error_codes, errorCode, message, Message } = response;

    this.codes = error_codes || [errorCode || error_code || ''];
    this.message = message || Message || '';

    // Added this to make **instanceof** work in Jest
    Object.setPrototypeOf(this, ApiError.prototype);
  }

  public toString = (): string => {
    return this.message;
  };
}

export { ApiError };
