import { EngineAction, MasterReferenceAction } from '../actions/types';
import { EngineConstants, MasterReferenceConstants } from '../constants';

const initialState: STATES.AddEngineState = {
  errorCode: 0,
  hasError: false,
  startUpload: false,
  uploadPercentage: 0,
  executePercentage: 0,
  errorMessage: '',
  isUploading: false,
  isUploaded: false,
  isMissingReleaseNote: false,
  referenceFiles: [],
  isSubmmitReleaseNote: false,
  startUploadTime: 0,
  endUploadTime: 0,
  uploadProgress: 0,
  startExecuteTime: 0,
  endExecuteTime: 0,
  executeProgress: 0,
  addEngineError: null,
  addEngineStats: null,
  executeResult: null,
  xhrRef: null,
  isPublishing: false,
  publishedSuccess: false,
  publishingEngineName: null,
  lastAddEngineStats: null,
  currentStep: 0,
  activePeriod: null,
  originalFileDocumentId: '',
  engineFileDocumentId: '',
  referenceRangeMetadatas: [],
};

export const addEngine = (
  state = initialState,
  action: EngineAction | MasterReferenceAction
): STATES.AddEngineState => {
  switch (action.type) {
    case EngineConstants.ADD_ENGINE_UPLOAD_START:
      return {
        ...initialState,
        isUploading: true,
        startUploadTime: new Date().getTime(),
        uploadProgress: 5,
        isUploaded: false,
        lastAddEngineStats: null,
      };

    case EngineConstants.ADD_ENGINE_UPLOAD_PROGRESS: {
      const { uploadProgress } = action.payload;

      return {
        ...state,
        uploadProgress,
      };
    }

    case EngineConstants.ADD_ENGINE_EXECUTE_START: {
      const now = new Date().getTime();

      return {
        ...state,
        uploadProgress: 100,
        endUploadTime: now,
        startExecuteTime: now,
        executeProgress: 5,
      };
    }

    case EngineConstants.ADD_ENGINE_EXECUTE_PROGRESS: {
      const { executeProgress } = action.payload;

      return {
        ...state,
        executeProgress,
      };
    }

    case EngineConstants.ADD_ENGINE_SUCCESS: {
      const { result } = action.payload;
      const { startUploadTime, endUploadTime, startExecuteTime } = state;

      const endExecuteTime = new Date().getTime();
      const uploadTime = endUploadTime - startUploadTime;

      // Mapp result to state
      const {
        bookSummary,
        xlInputs,
        totalXJInputs,
        xOutput,
        xReferenceBlocks,
        xReferenceTables,
        totalXJOutputs,
        totalOutputSolves,
        totalReports,
        releaseNoteRequired,
        validationErrors,
        totalInputTables,
        totalHiddenSheets,
        totalOutputTables,
        totalSheets,
        sizeOfUploadFile,
        sizeOfCompressedFile,
        referenceRangeMetadatas,
        warnings,
        effectiveEndDate,
        effectiveStartDate,
        originalFileDocumentId,
        engineFileDocumentId,
      } = result;

      const addEngineStats: DTO.AddEngineStats = {
        results: {
          author: bookSummary.bookAuthor,
          uploadDate: bookSummary.bookUploadDate,
          releaseNoteRequired,
          resleaseNote: bookSummary.bookVersionReleaseNotes,
          releaseDate: bookSummary.bookReleaseDate,
          email: bookSummary.bookUploaderEmail,
          serviceName: bookSummary.bookServiceName,
          revision: bookSummary.bookRevision,
          fileUUID: bookSummary.bookFileGUID,
          fileHash: bookSummary.bookFileHash,
          fileUploadSize: sizeOfUploadFile,
          compressedFileSize: sizeOfCompressedFile,
          docstoreSize: bookSummary.docstoreSize,
          extractModelTime: bookSummary.timeExtractModel,
          totalServerTime: bookSummary.totalTime,
          uploadToDocstoreTime: bookSummary.uploadTime,
          executeTime: endExecuteTime - startExecuteTime,
          uploadTime,
          effectiveStartDate: bookSummary.effectiveStartDate,
          effectiveEndDate: bookSummary.effectiveEndDate,
          sheets: {
            total: totalSheets,
            hidden: totalHiddenSheets,
          },
        },
        inputs: {
          singleValues: xlInputs.length,
          tables: totalInputTables,
          richJSON: totalXJInputs,
        },
        outputs: {
          singleValues: xOutput.length,
          tables: totalOutputTables,
          richJSON: totalXJOutputs,
          solves: totalOutputSolves,
          reports: totalReports,
          mainframeSets: '',
        },
        references: {
          tableRangeCount: xReferenceTables.length,
          blockRangeCount: xReferenceBlocks.length,
          tableRange: xReferenceTables.map(r => r.name).join(),
          blockRange: xReferenceBlocks.map(r => r.name).join(),
        },
        errors: validationErrors,
        warnings,
        effectiveStartDate: effectiveStartDate || undefined,
        effectiveEndDate: effectiveEndDate || undefined,
        referenceRangeMetadatas,
        originalFileDocumentId,
        engineFileDocumentId,
      };
      return {
        ...state,
        isUploading: false,
        isSubmmitReleaseNote: false,
        isMissingReleaseNote: releaseNoteRequired,
        uploadProgress: 100,
        executeProgress: 100,
        addEngineStats,
        executeResult: result,
        isUploaded: true,
        endExecuteTime,
        referenceRangeMetadatas,
        currentStep: 1,
      };
    }

    case EngineConstants.UPDATE_ACTIVE_PERIOD: {
      const { activePeriod } = action.payload;
      return {
        ...state,
        activePeriod,
      };
    }

    case MasterReferenceConstants.MASTER_REF_SYNC_SUCCESS: {
      return {
        ...state,
        currentStep: 0,
      };
    }

    case EngineConstants.UPDATE_RELEASE_NOTE: {
      const { releaseNote } = action.payload;
      return {
        ...state,
        releaseNote,
      };
    }

    case EngineConstants.CANCEL_PUBLISH_ENGINE: {
      return {
        ...initialState,
        publishingEngineName: null,
      };
    }

    case EngineConstants.PUBLISH_UPLOADED_ENGINE: {
      return {
        ...state,
        isPublishing: true,
      };
    }

    case EngineConstants.PUBLISH_UPLOADED_ENGINE_SUCCESS: {
      return {
        ...initialState,
      };
    }

    case EngineConstants.PUBLISH_UPLOADED_ENGINE_FAILURE: {
      return {
        ...state,
        isPublishing: false,
      };
    }

    case EngineConstants.ADD_ENGINE_UPLOAD_XHR_REF: {
      const { xhrRef } = action.payload;

      return {
        ...state,
        xhrRef,
      };
    }

    case EngineConstants.ADD_ENGINE_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isUploading: false,
        isSubmmitReleaseNote: false,
        addEngineError: error,
      };
    }

    case EngineConstants.ADD_ENGINE_RESET: {
      return {
        ...initialState,
        publishingEngineName: state.publishingEngineName,
        referenceRangeMetadatas: state.referenceRangeMetadatas,
        referenceFiles: state.referenceFiles,
        lastAddEngineStats: state.lastAddEngineStats,
      };
    }

    case EngineConstants.UPDATE_ENGINE_PROPS_FAILURE:
    case EngineConstants.UPDATE_ENGINE_PROPS_SUCCESS: {
      return {
        ...state,
        currentStep: 0,
      };
    }
    case EngineConstants.UPDATE_ENGINE_PROPS_REQUEST: {
      return {
        ...state,
        currentStep: 2,
      };
    }

    default:
      return state;
  }
};
