import { ViewLogsAction } from '../actions/types';
import { ViewLogsConstant } from '../constants';

export const initialState: STATES.ViewLogsState = {
  isLoading: false,
  logs: [],
  page: 1,
  totalPages: 0,
  pageSize: 15,
  searchText: '',
  startDate: '',
  endDate: '',
};

export const engineViewLogs = (
  state = initialState,
  action: ViewLogsAction
): STATES.ViewLogsState => {
  switch (action.type) {
    case ViewLogsConstant.GET_ENGINE_VIEW_LOGS: {
      const {
        page,
        searchText = '',
        startDate = '',
        endDate = '',
      } = action.payload;

      return {
        ...state,
        isLoading: true,
        page,
        searchText,
        startDate,
        endDate,
      };
    }
    case ViewLogsConstant.GET_ENGINE_VIEW_LOGS_SUCCESS: {
      const {
        logs,
        total,
        page,
        startDate,
        searchText = '',
        endDate,
      } = action.payload;

      if (
        searchText !== state.searchText ||
        page !== state.page ||
        startDate !== state.startDate ||
        endDate !== state.endDate
      ) {
        return state;
      }

      return {
        ...state,
        isLoading: false,
        logs: page === 1 ? logs : [...state.logs, ...logs],
        totalPages:
          Math.floor(total / state.pageSize) + (total % state.pageSize ? 1 : 0),
      };
    }
    case ViewLogsConstant.GET_ENGINE_VIEW_LOGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ViewLogsConstant.ENGINE_LOGS_RESET: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
