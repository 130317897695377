import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { LanguageConstants } from '../../constants';
import { colorHexToRgba } from '../../helpers';
import { useLanguage } from '../../hooks';

interface LanguageSwitchProps {
  className?: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;

  a {
    font-size: 14px;
    line-height: 1;
    color: ${({ theme }) => colorHexToRgba(theme.color.aqua, 0.6)};
    padding: 0 15px;
    border-right: 1px solid
      ${({ theme }) => colorHexToRgba(theme.color.aqua, 0.6)};

    &:last-child {
      border-right: none;
    }

    &.active {
      font-weight: bold;
      color: ${({ theme }) => theme.color.aqua};
    }
  }
`;

const LanguageSwitch: FC<LanguageSwitchProps> = ({ className }) => {
  const { language, setLanguage } = useLanguage();

  return (
    <Container className={className}>
      {LanguageConstants.SUPPORTED_LANGUAGES.map(lang => (
        <Link
          key={lang}
          to="/"
          onClick={e => {
            e.preventDefault();

            setLanguage(lang);
          }}
          className={lang === language ? 'active' : ''}
        >
          <FormattedMessage
            id={`LanguageDropdown.short.${lang}`}
            defaultMessage={lang}
          />
        </Link>
      ))}
    </Container>
  );
};

export { LanguageSwitch };
