import { css } from 'styled-components';

const ModalCommonCss = css`
  .ant-modal-header {
    padding: 24px 24px 0 24px;
    background: transparent;
    text-align: center;

    .ant-modal-title {
      color: ${({ theme }) => theme.color.dark};
      font-size: 25px;
      font-weight: normal;
      line-height: 1.2;

      .modal-title-icon {
        font-size: 50px;
        color: ${({ theme }) => theme.color.aqua};
      }
    }
  }

  .ant-modal-footer {
    padding: 0 24px 24px 24px;
    text-align: center;

    .ant-btn {
      min-width: 200px;
    }

    button + button {
      margin-left: 24px;
    }
  }

  .ant-modal-close-x {
    font-size: 20px;
  }

  .ant-spin-nested-loading.absolute-mode {
    position: absolute;
    top: 10px;
    left: 10px;
    bottom: 10px;
    right: 0;
    display: flex;

    .ant-spin-blur {
      flex: 1;
      background-color: rgba(255, 255, 255, 0.5);
      opacity: 1;
      border-radius: 10px;
    }
  }
`;

export { ModalCommonCss };
