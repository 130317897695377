import { ProductsBoardConstants } from '../constants';
import { ProductsBoardThunkAction } from './types';
import { AlertActions } from './alert.actions';
import { ApiError } from '../helpers';
import { ProductsBoardServices, ProductService } from '../services';

const getProducts = (
  data: DTO.GetProductsRequest
): ProductsBoardThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductsBoardConstants.BOARD_GET_PRODUCTS_REQUEST,
    });

    const { status, payload } = await ProductService.getSharedProducts(data);

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsBoardConstants.BOARD_GET_PRODUCTS_SUCCESS,
      payload: { products: payload.data },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsBoardConstants.BOARD_GET_PRODUCTS_FAILURE,
      payload: { error: msg },
    });
  }
};

const getStatusCols = (): ProductsBoardThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductsBoardConstants.BOARD_GET_STATUS_COLS_REQUEST,
    });

    const { status, payload } = await ProductsBoardServices.getStatuses();

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsBoardConstants.BOARD_GET_STATUS_COLS_SUCCESS,
      payload: { statuses: payload.data },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsBoardConstants.BOARD_GET_STATUS_COLS_FAILURE,
      payload: { error: msg },
    });
  }
};

const moveColumn = (
  columnId: string,
  fromIndex: number,
  toIndex: number
): ProductsBoardThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductsBoardConstants.BOARD_MOVE_COLUMN_REQUEST,
      payload: {
        columnId,
        fromIndex,
        toIndex,
      },
    });

    const { payload, status } = await ProductsBoardServices.updateColumn(
      columnId,
      columnId,
      toIndex
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsBoardConstants.BOARD_MOVE_COLUMN_SUCCESS,
      payload: {
        columnId,
        fromIndex,
        toIndex,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsBoardConstants.BOARD_MOVE_COLUMN_FAILURE,
      payload: {
        columnId,
        fromIndex,
        toIndex,
        error: msg,
      },
    });
  }
};

const moveCardBetweenCols = (
  productId: string,
  fromCol: string,
  fromIndex: number,
  toCol: string,
  toIndex: number
): ProductsBoardThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductsBoardConstants.BOARD_MOVE_CARD_BW_COLS_REQUEST,
      payload: {
        productId,
        fromCol,
        fromIndex,
        toCol,
        toIndex,
      },
    });

    const { status, payload } = await ProductsBoardServices.updateKanbanStatus(
      productId,
      toCol
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsBoardConstants.BOARD_MOVE_CARD_BW_COLS_SUCCESS,
      payload: {
        productId,
        fromCol,
        fromIndex,
        toCol,
        toIndex,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsBoardConstants.BOARD_MOVE_CARD_BW_COLS_FAILURE,
      payload: {
        productId,
        fromCol,
        fromIndex,
        toCol,
        toIndex,
        error: msg,
      },
    });
  }
};

const addCol = (
  columnName: string,
  columnIndex: number
): ProductsBoardThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: ProductsBoardConstants.BOARD_ADD_COLUMN_REQUEST,
      payload: {
        columnIndex,
        columnName,
      },
    });

    const { payload, status } = await ProductsBoardServices.addColumn(
      columnName,
      columnIndex
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsBoardConstants.BOARD_ADD_COLUMN_SUCCESS,
      payload: {
        columnIndex,
        columnName,
      },
    });

    dispatch(AlertActions.success('ProductBoard.actions.addCol.success'));

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsBoardConstants.BOARD_ADD_COLUMN_FAILURE,
      payload: {
        error: msg,
        columnIndex,
        columnName,
      },
    });

    return false;
  }
};

const editCol = (
  columnName: string,
  columnIndex: number,
  fromColumnName: string,
  fromColumnIndex: number
): ProductsBoardThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: ProductsBoardConstants.BOARD_EDIT_COLUMN_REQUEST,
      payload: {
        columnName,
        columnIndex,
        fromColumnName,
        fromColumnIndex,
      },
    });

    const { payload, status } = await ProductsBoardServices.updateColumn(
      columnName,
      fromColumnName,
      columnIndex
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch(AlertActions.success('ProductBoard.actions.editCol.success'));

    dispatch({
      type: ProductsBoardConstants.BOARD_EDIT_COLUMN_SUCCESS,
      payload: {
        columnName,
        columnIndex,
        fromColumnName,
        fromColumnIndex,
      },
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsBoardConstants.BOARD_EDIT_COLUMN_FAILURE,
      payload: {
        error: msg,
        columnName,
        columnIndex,
        fromColumnName,
        fromColumnIndex,
      },
    });

    return false;
  }
};

const deleteCol = (
  columnName: string
): ProductsBoardThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: ProductsBoardConstants.BOARD_DELETE_COLUMN_REQUEST,
      payload: { columnName },
    });

    const { payload, status } = await ProductsBoardServices.deleteColumn(
      columnName
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsBoardConstants.BOARD_DELETE_COLUMN_SUCCESS,
      payload: { columnName },
    });

    dispatch(AlertActions.success('ProductBoard.actions.deleteCol.success'));

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsBoardConstants.BOARD_DELETE_COLUMN_FAILURE,
      payload: {
        columnName,
        error: msg,
      },
    });

    return false;
  }
};

export const ProductsBoardActions = {
  getProducts,
  getStatusCols,
  moveColumn,
  moveCardBetweenCols,
  addCol,
  editCol,
  deleteCol,
};
