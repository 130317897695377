import makeRequest from '../helpers/make-request';
import uploadRequest from '../helpers/upload-request';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;

const getMasterReferences = (request: DTO.GetMasterReferencesRequest) => {
  const {
    page = 1,
    pageSize = 11,
    sortBy = 'updated',
    sort = 'desc',
    searchText = '',
  } = request;

  const url = `${EXCELENGINE_DOMAIN}/api/MasterReference/list`;

  const search: { field: string; value: string }[] = [];
  if (searchText) {
    search.push({ field: 'referenceId_co', value: searchText });
  }

  return makeRequest<DTO.GetMasterReferencesResponse>('POST', url, {
    page,
    pageSize,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
    search,
  });
};

const upload = (
  file: File | undefined,
  documentId: string | null | undefined,
  request: DTO.UpdateMasterRefRequest,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const { effectiveStartDate, effectiveEndDate } = request;

  const url = `${EXCELENGINE_DOMAIN}/api/MasterReference/save`;
  const formData = new FormData();

  file && formData.append('file', file);
  documentId && formData.append('id', documentId);
  effectiveStartDate &&
    formData.append('effectiveStartDate', effectiveStartDate);
  effectiveEndDate && formData.append('effectiveEndDate', effectiveEndDate);

  return uploadRequest<DTO.UploadReferenceFileResponse>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const getDownloadReferenceFileUrl = (documentId: string, token: string) =>
  `${EXCELENGINE_DOMAIN}/api/MasterReference/Download/${documentId}?token=${encodeURIComponent(
    token
  )}`;

const deleteDocument = (documentId: string, serviceName: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/MasterReference/${documentId}/${serviceName}`;

  return makeRequest('DELETE', url);
};

const syncData = (request: DTO.SyncDataRequest) => {
  const { productName, serviceName, version } = request;
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/syncdata/${serviceName}/${version}`;
  return makeRequest<DTO.SyncDataResponse>('POST', url, request);
};

const getDetails = (documentId: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/MasterReference/${documentId}`;

  return makeRequest<{ data: DTO.MasterFileData }>('GET', url);
};

const getMasterAPIdefFile = () => {
  const url = `${EXCELENGINE_DOMAIN}/api/MasterApiDefinition/Get`;
  return makeRequest<DTO.GetMasterAPIdefFileResponse>('GET', url);
};

const getDownloadMasterAPIDefFileUrl = (documentName: string, token: string) =>
  `${EXCELENGINE_DOMAIN}/api/MasterApiDefinition/Download/${documentName}?token=${encodeURIComponent(
    token
  )}`;

const uploadMasterAPIDef = (
  file: File | undefined,
  documentId: string | null | undefined,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/MasterApiDefinition`;
  const formData = new FormData();

  file && formData.append('file', file);
  documentId && formData.append('id', documentId);

  return uploadRequest<DTO.UploadReferenceFileResponse>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const deleteMasterAPIDef = (documentId: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/MasterApiDefinition/${documentId}`;

  return makeRequest('DELETE', url);
};

const getDownStreamEngines = (documentId: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/MasterReference/GetDownStream/${documentId}`;
  return makeRequest<{ data: DTO.DownstreamEngine[] }>('GET', url);
};

const syncAllDownStreamEngines = (documentId: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/MasterReference/SyncAllDownStreamEngines/${documentId}`;
  return makeRequest<{ data: DTO.DownstreamEngine[] }>('POST', url);
};

const readReferenceRange = (documentId: string, referenceRange: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/MasterReference/read/${documentId}/${referenceRange}`;
  return makeRequest<{ data: string | null }>('GET', url);
};

const writeReferenceRange = (
  documentId: string,
  referenceRange: string,
  value: object | string
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/MasterReference/write/${documentId}/${referenceRange}`;
  return makeRequest('POST', url, value);
};

const getProductTutorials = () => {
  const url = `${EXCELENGINE_DOMAIN}/api/MasterApiDefinition/GetProductTutorials`;
  return makeRequest<{ data: DTO.ProductTutorial[] }>('GET', url);
};

const validateProductTutorial = (product: string, engine: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/MasterApiDefinition/ValidateProductTutorial/${product}/${engine}`;
  return makeRequest<{ data: DTO.ProductTutorialMisMatches }>('GET', url);
};

export const MasterReferenceServices = {
  getMasterReferences,
  upload,
  syncData,
  getDownloadReferenceFileUrl,
  deleteDocument,
  getDetails,
  getMasterAPIdefFile,
  getDownloadMasterAPIDefFileUrl,
  uploadMasterAPIDef,
  deleteMasterAPIDef,
  getDownStreamEngines,
  syncAllDownStreamEngines,
  readReferenceRange,
  writeReferenceRange,
  getProductTutorials,
  validateProductTutorial,
};
