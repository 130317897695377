import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { ClientManagerActions } from '../actions';

interface ClientManagerRouteParams {
  usergroupId: string;
}

let numberSubscriber = 0;

const clientManagerParamsSelector = ({
  clientManager: { selectedUserGroup, selectedUserGroupId, isLoadingUserGroup },
}: STATES.AppState) => ({
  selectedUserGroup,
  selectedUserGroupId,
  isLoadingUserGroup,
});

const useClientManagerParams = () => {
  const dispatch = useDispatch();
  const { usergroupId } = useParams<ClientManagerRouteParams>();
  const {
    selectedUserGroup,
    selectedUserGroupId,
    isLoadingUserGroup,
  } = useSelector(clientManagerParamsSelector);

  useEffect(() => {
    if (
      !usergroupId ||
      (isLoadingUserGroup && selectedUserGroupId === usergroupId)
    ) {
      return;
    }

    if (selectedUserGroupId !== usergroupId) {
      dispatch(ClientManagerActions.get(usergroupId));
    }
  }, [
    dispatch,
    selectedUserGroup,
    selectedUserGroupId,
    usergroupId,
    isLoadingUserGroup,
  ]);

  useEffect(() => {
    numberSubscriber += 1;

    return () => {
      numberSubscriber -= 1;

      if (numberSubscriber === 0) {
        dispatch(ClientManagerActions.select(null));
      }
    };
  }, [dispatch]);

  return {
    usergroupId,
    selectedUserGroup,
    isLoadingUserGroup,
  };
};

export { useClientManagerParams };
