import { PerformanceMetricsConstants } from '../constants';
import { EngineService } from '../services';
import { ApiError } from '../helpers';
import { AlertActions } from './alert.actions';
import {
  PerformanceMetricsAction,
  PerformanceMetricsThunkAction,
} from './types';

const get = (
  productName: string,
  request: DTO.PerformanceMetricsRequest
): PerformanceMetricsThunkAction => async dispatch => {
  try {
    dispatch({
      type: PerformanceMetricsConstants.GET_PERFORMANCE_METRICS_REQUEST,
      payload: {
        enginesOnLogs: request.Engines.map(engine => ({
          serviceName: engine.Engine,
          versionId: engine.Version,
          revision: engine.Revision,
        })),
      },
    });

    const { payload, status } = await EngineService.getPerformanceMetrics(
      productName,
      request
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: PerformanceMetricsConstants.GET_PERFORMANCE_METRICS_SUCCESS,
      payload: {
        data: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: PerformanceMetricsConstants.GET_PERFORMANCE_METRICS_FAILURE,
      payload: { error: msg },
    });
  }
};

const reset = (): PerformanceMetricsAction => ({
  type: PerformanceMetricsConstants.PERFORMANCE_METRICS_RESET,
});

export const PerformanceMetricsActions = {
  get,
  reset,
};
