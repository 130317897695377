import { Action } from 'redux';
import MainDefaultTheme from '../styles/MainDefaultTheme';

export const theme = (
  state = MainDefaultTheme,
  action: Action
): STATES.ThemeState => {
  switch (action.type) {
    default:
      return state;
  }
};
