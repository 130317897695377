import makeRequest from '../helpers/make-request';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;

const saveCategory = (data: DTO.SaveCategoryRequest) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/lookup/savecategory`;

  return makeRequest<DTO.GetUserGroupRulesResponse>('POST', url, data);
};

const deleteCategory = (key: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/lookup/deletecategory/${key}`;

  return makeRequest('DELETE', url);
};

const getCategories = () => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/lookup/getCategories`;

  return makeRequest<DTO.GetProductCategoryResponse>('GET', url);
};

export const CategoryManagerService = {
  saveCategory,
  deleteCategory,
  getCategories,
};
