import { FormBuilderConstants } from '../constants';
import { EngineService } from '../services';
import { AlertActions } from './alert.actions';
import { ApiError } from '../helpers';
import { FormBuilderThunkAction, FormBuilderAction } from './types';

const getFormSpec = (
  productName: string,
  serviceName: string
): FormBuilderThunkAction => async dispatch => {
  try {
    dispatch({
      type: FormBuilderConstants.GET_FORMSPEC_REQUEST,
    });

    const {
      payload: enginePayload,
      status: engineStatus,
    } = await EngineService.getProductEngineDetails(productName, serviceName);

    if (engineStatus !== 200 || enginePayload.status === 'Error') {
      throw new ApiError(enginePayload);
    }

    const {
      payload: formspecPayload,
      status: formspecStatus,
    } = await EngineService.getFormSpec(productName, serviceName);

    if (formspecStatus !== 200 || formspecPayload.status === 'Error') {
      throw new ApiError(formspecPayload);
    }

    dispatch({
      type: FormBuilderConstants.GET_FORMSPEC_SUCCESS,
      payload: {
        formspec: formspecPayload.data,
        documentVersion: enginePayload.data.bookSummary.versionId,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FormBuilderConstants.GET_FORMSPEC_FAILURE,
      payload: { error: msg },
    });
  }
};

const getFormState = (
  productName: string,
  serviceName: string,
  fields: DTO.FormStateInputField[]
): FormBuilderThunkAction => async dispatch => {
  try {
    dispatch({
      type: FormBuilderConstants.GET_FORMSTATE_REQUEST,
    });

    const { payload, status } = await EngineService.getFormState(
      productName,
      serviceName,
      fields
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: FormBuilderConstants.GET_FORMSTATE_SUCCESS,
      payload: { formstate: payload.data },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FormBuilderConstants.GET_FORMSTATE_FAILURE,
      payload: { error: msg },
    });
  }
};

const resetFormBuilder = (): FormBuilderAction => ({
  type: FormBuilderConstants.RESET_FORMBUILDER,
});

export const FormBuilderActions = {
  getFormSpec,
  getFormState,
  resetFormBuilder,
};
