import { CategoryManagerConstants } from '../constants';
import { ApiError } from '../helpers';
import { CategoryManagerService } from '../services';
import { AlertActions } from './alert.actions';
import { CategoryManagerThunkAction } from './types';

const saveCategory = (
  data: DTO.SaveCategoryRequest
): CategoryManagerThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: CategoryManagerConstants.SAVE_CATEGORY_REQUEST,
    });

    const { payload, status } = await CategoryManagerService.saveCategory(data);

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: CategoryManagerConstants.SAVE_CATEGORY_SUCCESS,
      payload: { key: data.key, icon: data.icon, value: data.value },
    });
    dispatch(AlertActions.success('CategoryManager.form.save.message.success'));
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: CategoryManagerConstants.SAVE_CATEGORY_FAILURE,
      payload: { error: msg },
    });
    return false;
  }
};

const deleteCategory = (
  key: string
): CategoryManagerThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: CategoryManagerConstants.DELETE_CATEGORY_REQUEST,
    });

    const { payload, status } = await CategoryManagerService.deleteCategory(
      key
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: CategoryManagerConstants.DELETE_CATEGORY_SUCCESS,
      payload: { key },
    });
    dispatch(
      AlertActions.success('CategoryManager.form.delete.message.success')
    );
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: CategoryManagerConstants.DELETE_CATEGORY_FAILURE,
      payload: { error: msg },
    });
    return false;
  }
};

const getCategories = (): CategoryManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: CategoryManagerConstants.GET_CATEGORIES_REQUEST,
    });

    const { payload, status } = await CategoryManagerService.getCategories();

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: CategoryManagerConstants.GET_CATEGORIES_SUCCESS,
      payload: { categories: payload.data },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: CategoryManagerConstants.GET_CATEGORIES_FAILURE,
      payload: { error: msg },
    });
  }
};

export const CategoryManagerActions = {
  saveCategory,
  deleteCategory,
  getCategories,
};
