const downloadBlob = (blob: Blob, fileName: string) => {
  const temp = document.createElement('a');

  temp.style.display = 'none';

  const blobUrl = window.URL.createObjectURL(blob);

  temp.href = blobUrl;
  temp.download = fileName;

  document.body.appendChild(temp);
  temp.click();

  setTimeout(() => {
    document.body.removeChild(temp);
  }, 100);
};

const downloadFile = (url: string, fileName: string) => {
  const temp = document.createElement('a');

  temp.style.display = 'none';

  temp.href = url;
  temp.download = fileName;

  document.body.appendChild(temp);
  temp.click();

  setTimeout(() => {
    document.body.removeChild(temp);
  }, 100);
};

const generateCSV = <T extends {}>(data: T[], fileName: string) => {
  const emptyHandler = (_, value) => (value == null ? '' : value);
  const headers = Object.keys(data[0]);
  const csv = data.map(entry =>
    headers.map(field => JSON.stringify(entry[field], emptyHandler)).join(',')
  );
  csv.unshift(headers.join(','));

  const blob = new Blob(['\ufeff', csv.join('\r\n')], { type: 'text/csv' });
  downloadBlob(blob, fileName);
};

export { downloadBlob, generateCSV, downloadFile };
