import React, { FC, useRef } from 'react';
import { DatePicker } from 'antd';
import styled from 'styled-components';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import { colorHexToRgba } from '../../../helpers';

const StyledDatePicker = styled(DatePicker)`
  && {
    margin-right: 20px;

    .ant-input {
      border: 1px solid ${({ theme }) => theme.color.gray4};
      background: ${({ theme }) => theme.color.white};
      padding-right: 30px;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .ant-input[disabled] {
      background: ${({ theme }) => theme.color.gray6};
    }

    .anticon {
      font-size: 20px;
      height: 40px;
      width: 20px;
      right: 7px !important;
      top: 8px !important;
      bottom: 0;
      line-height: 40px;
      color: ${({ theme }) => theme.color.aqua};
      &.anticon-close-circle {
        display: none;
      }
    }
  }
`;

const PickerContainer = styled.div`
  .ant-calendar-picker-container {
    background: ${({ theme }) => theme.color.white};
    border-radius: 4px;
  }

  .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
    top: 2px;
    left: 2px;
    width: 26px;
    height: 26px;
  }

  .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
    top: 2px;
    left: 2px;
    width: 26px;
    height: 26px;
  }

  .ant-calendar-disabled-cell.ant-calendar-selected-day
    .ant-calendar-date::before {
    top: 2px;
    left: 2px;
    width: 26px;
    height: 26px;
  }

  .ant-calendar {
    background: ${({ theme }) => colorHexToRgba(theme.color.white, 0.4)};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.white};

    .ant-calendar-disabled-cell .ant-calendar-date {
      background-color: ${({ theme }) => colorHexToRgba(theme.color.dark, 0.4)};
    }
  }

  .ant-calendar-input-wrap {
    background: ${({ theme }) => theme.color.dark};
    height: 40px;
    padding: 0;
    border-bottom: 1px solid #fff;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-radius: 5px 5px 0 0;

    .ant-calendar-input {
      height: 40px;
      font-size: 1rem;
      font-weight: bold;
      padding: 4px 10px;
      text-align: center;
      background: ${({ theme }) => theme.color.dark};
      box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      color: ${({ theme }) => theme.color.aqua};
    }
  }

  .ant-calendar-header {
    background: ${({ theme }) => theme.color.aqua};

    .ant-calendar-year-panel {
      .ant-calendar-year-panel-header {
        background: ${({ theme }) => theme.color.aqua};

        .ant-calendar-year-panel-prev-decade-btn:hover,
        .ant-calendar-year-panel-prev-decade-btn,
        .ant-calendar-year-panel-next-decade-btn:hover,
        .ant-calendar-year-panel-next-decade-btn {
          ::before,
          ::after {
            border-color: ${({ theme }) => theme.color.white};
          }
        }
      }
    }

    .ant-calendar-month-panel {
      .ant-calendar-month-panel-header {
        background: ${({ theme }) => theme.color.aqua};

        .ant-calendar-month-panel-prev-year-btn:hover,
        .ant-calendar-month-panel-prev-year-btn,
        .ant-calendar-month-panel-next-year-btn:hover,
        .ant-calendar-month-panel-next-year-btn {
          ::before,
          ::after {
            border-color: ${({ theme }) => theme.color.white};
          }
        }
      }
    }

    .ant-calendar-prev-year-btn,
    .ant-calendar-prev-month-btn,
    .ant-calendar-next-month-btn,
    .ant-calendar-next-year-btn {
      ::before,
      ::after {
        border-color: ${({ theme }) => theme.color.white};
      }
    }

    .ant-calendar-prev-year-btn:hover,
    .ant-calendar-prev-month-btn:hover,
    .ant-calendar-next-month-btn:hover,
    .ant-calendar-next-year-btn:hover {
      ::before,
      ::after {
        border-color: ${({ theme }) => theme.color.white};
      }
    }

    a:hover {
      color: ${({ theme }) => theme.color.white};
    }
  }

  .ant-calendar-table {
    .ant-calendar-column-header .ant-calendar-column-header-inner {
      font-weight: bold;
      font-size: 12px;
    }
    .ant-calendar-date {
      font-size: 12px;
      color: ${({ theme }) => theme.color.dark};
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      border: none;

      &:active {
        background: ${({ theme }) => theme.color.white};
      }
    }

    .ant-calendar-selected-day .ant-calendar-date {
      background-color: ${({ theme }) => colorHexToRgba(theme.color.aqua, 0.4)};
    }

    .ant-calendar-disabled-cell {
      .ant-calendar-date {
        background-color: transparent;
        color: ${({ theme }) => colorHexToRgba(theme.color.dark, 0.4)};
      }
    }
  }

  .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
  .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
    background-color: ${({ theme }) => colorHexToRgba(theme.color.aqua, 0.4)};
    color: ${({ theme }) => theme.color.dark};
  }

  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
  .ant-calendar-month-panel-selected-cell
    .ant-calendar-month-panel-month:hover {
    background-color: ${({ theme }) => colorHexToRgba(theme.color.aqua, 0.4)};
    color: ${({ theme }) => theme.color.dark};
  }

  .ant-calendar-year-panel-cell .ant-calendar-year-panel-year:hover,
  .ant-calendar-month-panel-cell .ant-calendar-month-panel-month:hover {
    background-color: ${({ theme }) => colorHexToRgba(theme.color.aqua, 0.4)};
    color: ${({ theme }) => theme.color.dark};
  }

  tr td {
    font-weight: normal !important;
  }

  .ant-calendar-time-picker-select {
    height: 263px;
  }

  .ant-calendar-footer {
    background: ${({ theme }) => theme.color.aqua};
    border-radius: 0 0 5px 5px;

    .ant-calendar-today-btn {
      color: ${({ theme }) => theme.color.white};
    }
    .ant-calendar-time-picker-btn {
      font-weight: 500;
      color: ${({ theme }) => theme.color.dark};
    }
    .ant-calendar-ok-btn,
    .ant-calendar-ok-btn:hover {
      background-color: ${({ theme }) => theme.color.dark};
      color: ${({ theme }) => theme.color.white};
      border: none;
      font-size: 12px;
      vertical-align: top;
      margin-top: 7px;
    }
  }
`;

const CustomDatePicker: FC<DatePickerProps> = props => {
  const calendarContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <PickerContainer
        className="custom-date-picker-container"
        ref={calendarContainerRef}
      />

      <StyledDatePicker
        getCalendarContainer={() =>
          calendarContainerRef.current || document.body
        }
        {...props}
      />
    </div>
  );
};

export { CustomDatePicker };
