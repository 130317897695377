import React, { memo, NamedExoticComponent } from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';

interface AlertProps {
  icon?: string;
  iconTheme?: 'filled' | 'outlined' | 'twoTone';
  type?: 'success' | 'info' | 'warning' | 'error';
  message?: string;
  description?: string;
  className?: string;
}

const StyledAlert = styled.div`
  && {
    background: ${({ theme }) => theme.color.white};
    text-align: center;
    padding: 7px 15px 7px 64px;
    margin-bottom: 20px;
    border: 2px solid #000;
    position: relative;

    .alert-icon {
      position: absolute;
      left: 20px;
      top: 13px;
      font-size: 35px;
      line-height: 35px;
      color: inherit;
    }

    .alert-message {
      font-weight: bold;
      font-weight: 18px;
    }

    .alert-description {
      font-weight: normal;
      font-weight: 16px;
    }

    span {
      line-height: 23px;
      margin-bottom: 0px;
    }
  }
`;

const CustomAlert: NamedExoticComponent<AlertProps> = memo(
  ({ icon, iconTheme, type, message, description, className }) => {
    return (
      <StyledAlert className={className} data-type={type}>
        <div className="alert-icon">
          <Icon type={icon} theme={iconTheme} />
        </div>
        <div className="alert-content">
          <div className="alert-message">{message}</div>
          <div className="alert-description">{description}</div>
        </div>
      </StyledAlert>
    );
  }
);

export { CustomAlert };
