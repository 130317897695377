import { DataDictionaryConstants } from '../constants';
import { ApiError } from '../helpers';
import { DataDictionaryService } from '../services';
import { AlertActions } from './alert.actions';
import { DataDictionaryThunkAction } from './types';
import { createAsyncThunk } from './helpers';

const getDictionaryData = (
  data: DTO.GetDataDictionaryRequest
): DataDictionaryThunkAction => async dispatch => {
  try {
    dispatch({
      type: DataDictionaryConstants.GET_DATADICTIONARY_REQUEST,
      payload: { ...data },
    });

    const { payload } = await DataDictionaryService.getDictionaryData(data);

    if (payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: DataDictionaryConstants.GET_DATADICTIONARY_SUCCESS,
      payload: {
        ...data,
        dataDictionary: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: DataDictionaryConstants.GET_DATADICTIONARY_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const downloadDataDictionaryFile = (): DataDictionaryThunkAction => (
  _,
  getState
) => {
  const {
    auth: { userAuth },
  } = getState();

  if (!userAuth) {
    return;
  }

  const { id_token } = userAuth;

  const downloadUrl = DataDictionaryService.downloadDataDictionary(id_token);

  window.location.href = downloadUrl;
};

const uploadDataDictionary = (file: File) =>
  createAsyncThunk(
    async (dispatch, getState) => {
      dispatch({
        type: DataDictionaryConstants.UPLOAD_DATADICTIONARY_START,
      });

      const {
        status,
        payload,
      } = await DataDictionaryService.uploadDataDictionary(
        file,
        uploadProgress => {
          if (uploadProgress === 100) {
            return;
          }

          // 5% for server processing time
          dispatch({
            type: DataDictionaryConstants.UPLOAD_DATADICTIONARY_PROGRESS,
            payload: {
              uploadProgress: Math.max(5, uploadProgress - 5),
            },
          });
        },
        xhrRef => {
          dispatch({
            type: DataDictionaryConstants.UPLOAD_DATADICTIONARY_XHR_REF,
            payload: { xhrRef },
          });
        }
      );

      if (status !== 200 || payload.status !== 'Success') {
        const {
          language: { intl },
        } = getState();

        dispatch({
          type: DataDictionaryConstants.UPLOAD_DATADICTIONARY_FAILURE,
          payload: {
            error: intl.formatMessage({ id: 'INVALID_DATADICTIONARY_ZIP' }),
          },
        });

        return;
      }

      dispatch({
        type: DataDictionaryConstants.UPLOAD_DATADICTIONARY_SUCCESS,
        payload: {
          result: payload.data,
        },
      });
    },
    (_, msg, dispatch) => {
      dispatch({
        type: DataDictionaryConstants.UPLOAD_DATADICTIONARY_FAILURE,
        payload: { error: msg },
      });
    }
  );

export const DataDictionaryActions = {
  getDictionaryData,
  downloadDataDictionaryFile,
  uploadDataDictionary,
};
