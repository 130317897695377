import { ProductDocsAction, ProductsAction } from '../actions/types';
import { ProductDocsConstant, ProductsConstants } from '../constants';

const initialState: STATES.AddDocumentState = {
  documentType: 'PRODUCTDETAILS',
  uploadPercentage: 0,
  isUploading: false,
  uploadProgress: 0,
  error: null,
  xhrRef: null,
};

export const addDocument = (
  state = initialState,
  action: ProductDocsAction | ProductsAction
): STATES.AddDocumentState => {
  switch (action.type) {
    case ProductsConstants.UPLOAD_DOCUMENT: {
      const { documentType } = action.payload;

      return {
        ...state,
        documentType,
      };
    }
    case ProductDocsConstant.ADD_DOCUMENT_UPLOAD_START: {
      return {
        ...state,
        isUploading: true,
        uploadProgress: 5,
      };
    }
    case ProductDocsConstant.ADD_DOCUMENT_UPLOAD_XHR_REF: {
      const { xhrRef } = action.payload;
      return {
        ...state,
        xhrRef,
      };
    }
    case ProductDocsConstant.ADD_DOCUMENT_UPLOAD_PROGRESS: {
      const { uploadProgress } = action.payload;

      return {
        ...state,
        uploadProgress,
      };
    }
    case ProductDocsConstant.ADD_DOCUMENT_SUCCESS: {
      return {
        ...state,
        isUploading: false,
        uploadProgress: 100,
      };
    }
    case ProductDocsConstant.ADD_DOCUMENT_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isUploading: false,
        error,
      };
    }
    case ProductDocsConstant.ADD_DOCUMENT_RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
