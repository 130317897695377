import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { EngineActions } from '../actions';

interface EngineRouteParams {
  serviceName: string;
  productId: string;
  versionId?: string;
}

const engineParamsSelector = ({
  productEngines: { selectedEngine, isLoadingDetails, selectedEngineName },
  products: { selectedProduct },
}: STATES.AppState) => ({
  selectedEngine,
  isLoadingDetails,
  selectedEngineName,
  selectedProduct,
});

const useEngineParams = () => {
  const dispatch = useDispatch();
  const { serviceName, versionId, productId } = useParams<EngineRouteParams>();
  const {
    selectedEngine,
    selectedProduct,
    isLoadingDetails,
    selectedEngineName,
  } = useSelector(engineParamsSelector);

  useEffect(() => {
    if (!productId || !serviceName || (isLoadingDetails && selectedEngineName))
      return;

    if (
      !selectedEngine ||
      selectedEngine.bookSummary.bookServiceName !== serviceName ||
      (versionId && selectedEngine.bookSummary.bookServiceName !== versionId)
    ) {
      dispatch(
        EngineActions.getProductEngineDetails(productId, serviceName, versionId)
      );
      dispatch(EngineActions.getEngineVersions(productId, serviceName));
    }
  }, [
    dispatch,
    productId,
    serviceName,
    selectedEngine,
    versionId,
    isLoadingDetails,
    selectedEngineName,
  ]);

  return {
    selectedEngine,
    serviceName,
    versionId,
    selectedProduct,
  };
};

export { useEngineParams };
