import { FileManagerConstants } from '../constants';
import { FileManagerAction, FileManagerThunkAction } from './types';
import { AlertActions } from './alert.actions';
import { FileManagerService } from '../services';
import { ApiError, downloadFile } from '../helpers';

const getFolders = (
  folderPath: string
): FileManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: FileManagerConstants.GET_FOLDER_REQUEST,
      payload: {
        folderPath,
      },
    });

    const { status, payload } = await FileManagerService.getFolders(folderPath);
    if (
      status !== 200 ||
      !['Success', 'OK', null].includes(payload.status || null)
    ) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.GET_FOLDER_SUCCESS,
      payload: {
        folderPath,
        folders: payload.data.folders,
        files: payload.data.documents,
        total: payload.data.folders.length,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.GET_FOLDER_FAILURE,
      payload: {
        folderPath,
        error: msg,
      },
    });
  }
};

const uploadFile = (
  folderPath: string,
  file: File,
  metadata: object | null,
  documentId: string | null,
  privacyLevel: string
): FileManagerThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: FileManagerConstants.UPLOAD_FILE_START,
    });

    const { status, payload } = await FileManagerService.uploadFileToFolder(
      folderPath,
      documentId,
      metadata,
      file,
      privacyLevel,
      uploadProgress => {
        if (uploadProgress === 100) {
          return;
        }

        // 5% for server processing time
        dispatch({
          type: FileManagerConstants.UPLOAD_FILE_PROGRESS,
          payload: {
            uploadProgress: Math.max(5, uploadProgress - 5),
          },
        });
      },
      xhrRef => {
        dispatch({
          type: FileManagerConstants.UPLOAD_FILE_XHR_REF,
          payload: { xhrRef },
        });
      }
    );

    if (status !== 200 || !['Success', 'OK'].includes(payload.status || '')) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.UPLOAD_FILE_SUCCESS,
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.UPLOAD_FILE_FAILURE,
      payload: { error: msg },
    });

    return false;
  }
};

const updateFile = (
  id: string,
  file: DTO.EntityDetail
): FileManagerThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: FileManagerConstants.UPDATE_FILE_REQUEST,
      payload: {
        fileId: id,
      },
    });

    const { status, payload } = await FileManagerService.updateFile(id, file);

    if (status !== 200 || !['Success', 'OK'].includes(payload.status || '')) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.UPDATE_FILE_SUCCESS,
      payload: {
        fileId: id,
      },
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.UPDATE_FILE_FAILURE,
      payload: {
        fileId: id,
        error: msg,
      },
    });

    return false;
  }
};

const updateFolder = (
  id: string,
  folder: DTO.EntityDetail
): FileManagerThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: FileManagerConstants.UPDATE_FOLDER_REQUEST,
      payload: {
        folderId: id,
      },
    });

    const { status, payload } = await FileManagerService.updateFolder(
      id,
      folder
    );

    if (status !== 200 || !['Success', 'OK'].includes(payload.status || '')) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.UPDATE_FOLDER_SUCCESS,
      payload: {
        folderId: id,
      },
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.UPDATE_FOLDER_FAILURE,
      payload: {
        folderId: id,
        error: msg,
      },
    });

    return false;
  }
};

const resetUploadFile = (): FileManagerAction => ({
  type: FileManagerConstants.UPLOAD_FILE_RESET,
});

const openUploadModal = (folderPath: string): FileManagerAction => ({
  type: FileManagerConstants.UPLOAD_FILE_OPEN,
  payload: {
    folderPath,
  },
});

const closeUploadModal = (): FileManagerAction => ({
  type: FileManagerConstants.UPLOAD_FILE_CLOSE,
});

const addFolder = (): FileManagerAction => ({
  type: FileManagerConstants.ADD_FOLDER,
  payload: {
    timestamp: new Date().getTime().toFixed(0),
  },
});

const saveNewFolder = (
  parentFolderPath: string,
  folderName: string,
  folderTimestamp: string,
  privacyLevel: string
): FileManagerThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: FileManagerConstants.SAVE_NEW_FOLDER_REQUEST,
      payload: {
        folderTimestamp,
      },
    });

    const { status, payload } = await FileManagerService.createFolder(
      `${parentFolderPath}${folderName}`,
      privacyLevel
    );

    if (status !== 200 || !['Success', 'OK'].includes(payload.status || '')) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.SAVE_NEW_FOLDER_SUCCESS,
      payload: {
        folderTimestamp,
        folder: {
          id: payload.folder_id,
          name: folderName,
          path: payload.folder_path,
          metadata: {},
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
      },
    });
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.SAVE_NEW_FOLDER_FAILURE,
      payload: {
        folderTimestamp,
        error: msg,
      },
    });
    return false;
  }
};

const cancelNewFolder = (folderName: string): FileManagerAction => ({
  type: FileManagerConstants.CANCEL_NEW_FOLDER,
  payload: {
    folderName,
  },
});

const selectFolderToEdit = (
  id: string,
  folder: DTO.EntityDetail
): FileManagerAction => ({
  type: FileManagerConstants.GET_FOLDER_DETAILS_SUCCESS,
  payload: {
    id,
    folder,
  },
});

const selectFile = (
  fileId: string,
  file: DTO.EntityDetail
): FileManagerAction => ({
  type: FileManagerConstants.GET_FILE_DETAILS_SUCCESS,
  payload: {
    fileId,
    file,
  },
});

const getFileDetails = (
  fileId: string
): FileManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: FileManagerConstants.GET_FILE_DETAILS_REQUEST,
      payload: {
        fileId,
      },
    });

    const { status, payload } = await FileManagerService.getFileDetails(fileId);

    if (status !== 200 || !['Success', 'OK'].includes(payload.status || '')) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.GET_FILE_DETAILS_SUCCESS,
      payload: {
        fileId,
        file: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.GET_FILE_DETAILS_FAILURE,
      payload: {
        fileId,
        error: msg,
      },
    });
  }
};

const getFolderDetails = (
  id: string
): FileManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: FileManagerConstants.GET_FOLDER_DETAILS_REQUEST,
      payload: {
        id,
      },
    });

    const { status, payload } = await FileManagerService.getFolder(id);

    if (status !== 200 || !['Success', 'OK'].includes(payload.status || '')) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.GET_FOLDER_DETAILS_SUCCESS,
      payload: {
        id,
        folder: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.GET_FOLDER_DETAILS_FAILURE,
      payload: {
        id,
        error: msg,
      },
    });
  }
};

const deleteFolder = (
  folderName: string,
  id: string
): FileManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: FileManagerConstants.DELETE_FOLDER_REQUEST,
      payload: {
        id,
      },
    });

    const { status, payload } = await FileManagerService.deleteFolder(id);

    if (status !== 200 || !['Success', 'OK'].includes(payload.status || '')) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.DELETE_FOLDER_SUCCESS,
      payload: {
        id,
      },
    });

    dispatch(
      AlertActions.success('FileManagerFolders.menu.delete.success', {
        folderName,
      })
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.DELETE_FOLDER_FAILURE,
      payload: {
        error: msg,
        id,
      },
    });
  }
};

const downloadFileAction = (file: DTO.DSFile): FileManagerThunkAction => (
  _,
  getState
) => {
  const {
    auth: { userAuth },
  } = getState();

  if (!userAuth) {
    return;
  }

  const { id_token } = userAuth;

  const downloadUrl = FileManagerService.getDownloadFileUrl(
    file.id,
    file.name,
    id_token
  );
  downloadFile(downloadUrl, file.name);
};

const deleteFile = (
  fileName: string,
  fileId: string
): FileManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: FileManagerConstants.DELETE_FILE_REQUEST,
      payload: { fileId },
    });

    const { status, payload } = await FileManagerService.deleteFile(fileId);

    if (status !== 200 || !['Success', 'OK'].includes(payload.status || '')) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.DELETE_FILE_SUCCESS,
      payload: { fileId },
    });

    dispatch(
      AlertActions.success('FileManagerFiles.menu.delete.success', {
        fileName,
      })
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.DELETE_FILE_FAILURE,
      payload: {
        fileId,
        error: msg,
      },
    });
  }
};

export const FileManagerActions = {
  getFolders,
  addFolder,
  saveNewFolder,
  openUploadModal,
  closeUploadModal,
  uploadFile,
  cancelNewFolder,
  deleteFolder,
  getFileDetails,
  selectFile,
  resetUploadFile,
  deleteFile,
  downloadFileAction,
  selectFolderToEdit,
  getFolderDetails,
  updateFile,
  updateFolder,
};
