export const INTERVAL_NORMAL = 60000;
export const INTERVAL_HAS_RUNNING = 5000;

export const GET_BGJOBS_REQUEST = 'GET_BGJOBS_REQUEST';
export const GET_BGJOBS_SUCCESS = 'GET_BGJOBS_SUCCESS';
export const GET_BGJOBS_FAILURE = 'GET_BGJOBS_FAILURE';

export const GET_CANCEL_JOB_REQUEST = 'GET_CANCEL_JOB_REQUEST';
export const GET_CANCEL_JOB_SUCCESS = 'GET_CANCEL_JOB_SUCCESS';
export const GET_CANCEL_JOB_FAILURE = 'GET_CANCEL_JOB_FAILURE';

export const TRIGGER_REFRESH_GBJOBS = 'TRIGGER_REFRESH_GBJOBS';
export const TRIGGER_DOWNLOAD_GBJOBS = 'TRIGGER_DOWNLOAD_GBJOBS';
