import React, { Suspense, FC } from 'react';
import { RawIntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SuspenseLoading } from '../components/Layout';

const mapStateToAppProps = ({ language: { intl } }: STATES.AppState) => ({
  intl,
});

const GlobalProvider: FC = ({ children }) => {
  const { intl } = useSelector(mapStateToAppProps);

  const getUserConfirmation = (
    message: string,
    callback: (ok: boolean) => void
  ) => {
    const confirmModalTrigger = window[message];

    if (confirmModalTrigger) {
      confirmModalTrigger(callback);
      return;
    }

    callback(true);
  };

  return (
    <RawIntlProvider value={intl}>
      <BrowserRouter getUserConfirmation={getUserConfirmation}>
        <Suspense fallback={<SuspenseLoading />}>{children}</Suspense>
      </BrowserRouter>
    </RawIntlProvider>
  );
};

export default GlobalProvider;
