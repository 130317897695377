import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import ErrorLayout from './Layout/ErrorLayout';
import { IconSvg } from '../components/Common';

const StyledLayout = styled(Layout)`
  && {
    background-color: ${({ theme }) => theme.color.aqua};

    @media (max-width: 1440px) {
      .anticon {
        font-size: 700px;
      }
    }

    @media (min-width: 1025px) and (max-width: 1220px) {
      .anticon {
        font-size: 600px;
      }
    }

    @media (max-width: 1024px) {
      .anticon {
        font-size: 500px;
      }
    }

    @media (max-width: 700px) {
      .anticon {
        font-size: 400px;
      }
    }
  }

  .anticon {
    font-size: 800px;
    text-align: left;
    position: absolute;
    bottom: 0;
  }
`;

const StyleTextContainer = styled.div`
  color: ${({ theme }) => theme.color.dark};
  padding: 50px;
  position: absolute;
  right: 0;
  margin-top: 150px;
  margin-right: 600px;
  width: 700px;

  a {
    margin-left: 15px;
    color: ${({ theme }) => theme.color.white};
    text-decoration: underline;
    font-style: italic;
  }

  h1 {
    font-size: 130px;
  }

  h3 {
    font-size: 28px;
    font-weight: 400;
  }

  @media (min-width: 1441px) {
    margin-right: 200px;
  }

  @media (max-width: 1440px) {
    margin-top: 160px;
    margin-right: 10px;
    padding: 20px;
    width: 600px;
    h1 {
      font-size: 100px;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 24px;
      font-weight: 400;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 160px;
    margin-right: 80px;
    padding: 20px;
    width: 400px;
    h1 {
      font-size: 80px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 20px;
    }
  }

  @media (max-width: 700px) {
    margin-right: 70px;
    padding: 20px;
    width: 300px;
    margin-top: 20px;
    h1 {
      font-size: 70px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 18px;
    }
  }
`;

const ErrorPage = () => {
  return (
    <ErrorLayout>
      <StyledLayout>
        <IconSvg type="Alian" />
        <StyleTextContainer>
          <h1>
            <FormattedMessage id="Error404.Opps" />
          </h1>
          <h3>
            <FormattedMessage id="Error404.notFound" />
          </h3>
          <h3>
            <FormattedMessage id="Error404.notToWorry" />
            <a href="/">
              <FormattedMessage id="Error404.here" />
            </a>
          </h3>
        </StyleTextContainer>
      </StyledLayout>
    </ErrorLayout>
  );
};

export default ErrorPage;
