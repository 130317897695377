import { DmlFileAction } from '../actions/types';
import { DmlFileConstants } from '../constants';

export const initialDmlFileState: STATES.DmlFileState = {
  queueId: '',
  downloadDmlZipUrl: '',
  downloadDmlLogUrl: '',
  job: null,
};

export const dmlFile = (
  state = initialDmlFileState,
  action: DmlFileAction
): STATES.DmlFileState => {
  switch (action.type) {
    case DmlFileConstants.RUN_DML_UTILITY_REQUEST: {
      return {
        ...state,
        queueId: '',
        job: null,
        downloadDmlZipUrl: '',
        downloadDmlLogUrl: '',
      };
    }
    case DmlFileConstants.RUN_DML_UTILITY_SUCCESS: {
      const { queueId } = action.payload;

      return {
        ...state,
        queueId,
      };
    }
    case DmlFileConstants.RUN_DML_UTILITY_FAILURE: {
      return {
        ...state,
      };
    }
    case DmlFileConstants.GET_RUN_STATUS_REQUEST: {
      return {
        ...state,
      };
    }
    case DmlFileConstants.GET_RUN_STATUS_SUCCESS: {
      const { job } = action.payload;

      if (job.data.downloadFileName) {
        return {
          ...state,
          job: {
            ...job,
            progress: 100,
          },
          downloadDmlLogUrl: job.data.logFileName,
          downloadDmlZipUrl: job.data.downloadFileName,
        };
      }

      return {
        ...state,
        job,
        downloadDmlLogUrl: job?.data?.logFileName,
      };
    }
    case DmlFileConstants.GET_RUN_STATUS_FAILURE: {
      const tempJob = state.job;
      if (tempJob) {
        return {
          ...state,
          job: {
            ...tempJob,
            progress: 100,
            status: 'FAILED',
          },
        };
      }
      return {
        ...state,
      };
    }
    case DmlFileConstants.CLEAR_DML_JOB: {
      return {
        ...state,
        job: null,
      };
    }
    default:
      return state;
  }
};
