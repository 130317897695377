import React, { useRef, FC, useMemo, useEffect } from 'react';
import { Modal, Button, Typography, Table } from 'antd';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingIndicator, Logo, IconSvg } from '../components/Common';

import { SystemActions } from '../actions';

import { useLookupData } from '../hooks';

const StyledModal = styled.div`
  .ant-modal {
    width: 600px !important;
    max-width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .ant-btn {
    position: absolute;
    top: -15px;
    right: -20px;
    width: 50px;

    .anticon {
      font-size: 20px;
      color: ${({ theme }) => theme.color.dark};
    }
  }
`;

const Footer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;

  .ant-btn {
    min-width: 150px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;

  h3 {
    font-weight: normal !important;
    text-align: center;
    margin: 30px 0 15px 0;
  }

  h4 {
    margin-top: 0 !important;
    font-weight: bold;
    text-align: left;
    margin-bottom: 20px;
  }

  table {
    tr {
      td:first-child {
        padding-left: 0 !important;
      }

      :hover {
        td {
          background: transparent !important;
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
`;

interface AboutModalProps {
  visible: boolean;
  onClose: () => void;
}

const detailsTableColumn = [
  {
    dataIndex: 'label',
    key: 'label',
    width: 150,
  },
  {
    dataIndex: 'details',
    key: 'details',
  },
];

const mapStateToProps = ({
  system: { releaseDetails, isLoadingReleaseDetails },
}: STATES.AppState) => ({
  releaseDetails,
  isLoadingReleaseDetails,
});

const AboutModal: FC<AboutModalProps> = ({ visible, onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { releaseNote, releaseVersion, isLoadingLookupData } = useLookupData();
  const { releaseDetails, isLoadingReleaseDetails } = useSelector(
    mapStateToProps
  );
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!releaseDetails) {
      dispatch(SystemActions.getReleaseDetails());
    }
  }, [dispatch, releaseDetails]);

  const data = useMemo(() => {
    return [
      {
        label: intl.formatMessage({ id: 'AboutModal.table.versionNumber' }),
        details: releaseDetails && releaseDetails.releaseVersion,
      },
      {
        label: intl.formatMessage({ id: 'AboutModal.table.versionDetails' }),
        details: releaseNote,
      },
      {
        label: intl.formatMessage({ id: 'AboutModal.table.releasedDate' }),
        details: releaseDetails && releaseDetails.releaseDate,
      },
      {
        label: intl.formatMessage({ id: 'AboutModal.table.releaseVersion' }),
        details: releaseVersion,
      },
    ];
  }, [intl, releaseDetails, releaseNote, releaseVersion]);

  return (
    <>
      <StyledModal ref={containerRef} />
      <Modal
        visible={visible}
        footer={null}
        closable={false}
        getContainer={() => containerRef.current || document.body}
      >
        <LoadingIndicator
          spinning={isLoadingReleaseDetails || isLoadingLookupData}
        >
          <Header>
            <Button type="link" className="close" onClick={onClose}>
              <IconSvg type="close" />
            </Button>
          </Header>
          <Content>
            <Logo type="OnWhite" height="50px" />
            <Typography.Title level={3}>
              <FormattedMessage id="AboutModal.heading" />
            </Typography.Title>
            <Table
              showHeader={false}
              pagination={false}
              columns={detailsTableColumn}
              dataSource={data}
              rowKey="label"
            />
          </Content>
          <Footer>
            <Button
              data-testid="about-modal-ok-btn"
              type="primary"
              shape="round"
              onClick={onClose}
            >
              <FormattedMessage id="Global.ok" />
            </Button>
          </Footer>
        </LoadingIndicator>
      </Modal>
    </>
  );
};

export default AboutModal;
