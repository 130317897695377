import React, { memo, NamedExoticComponent } from 'react';
import { Button, Icon, Popover } from 'antd';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface TooltipProps {
  icon?: string;
  iconTheme?: 'filled' | 'outlined' | 'twoTone';
  text?: string;
  className?: string;
  placement?: TooltipPlacement;
  getTooltipContainer?: (triggerNode: HTMLElement) => HTMLElement;
}

const StyledButton = styled(Button)`
  && {
    padding-left: 5px;
    padding-right: 5px;

    .anticon {
      color: ${({ theme }) => theme.color.aqua};
    }
  }
`;

const StyledTooltipContainer = styled.div`
  display: flex;
  max-width: 300px;

  .content {
    padding: 0 5px;
  }
`;

const Tooltip: NamedExoticComponent<TooltipProps> = memo(
  ({
    icon = 'info-circle',
    text = '',
    iconTheme,
    placement = 'topRight',
    className,
    getTooltipContainer,
  }) => (
    <Popover
      content={
        <StyledTooltipContainer>
          <div>
            <Icon type={icon} theme="outlined" />
          </div>
          <div className="content">
            <FormattedMessage id={text} />
          </div>
        </StyledTooltipContainer>
      }
      trigger="click"
      placement={placement}
      openClassName={className}
      getPopupContainer={getTooltipContainer}
    >
      <StyledButton type="link">
        <Icon type={icon} theme={iconTheme} />
      </StyledButton>
    </Popover>
  )
);

export { Tooltip };
