import { ProductsRoadmapAction, ProductsAction } from '../actions/types';
import { ProductRoadMapConstants, ProductsConstants } from '../constants';
import { arrayReplaceFirst } from '../helpers';

const initialState: STATES.ProductsRoadmapState = {
  isLoading: false,
  products: [],
};

export const productRoadMap = (
  state = initialState,
  action: ProductsRoadmapAction | ProductsAction
): STATES.ProductsRoadmapState => {
  switch (action.type) {
    case ProductRoadMapConstants.GET_ROADMAP_PRODUCTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ProductRoadMapConstants.GET_ROADMAP_PRODUCTS_SUCCESS: {
      const { products } = action.payload;
      return {
        ...state,
        isLoading: false,
        products,
      };
    }
    case ProductRoadMapConstants.GET_ROADMAP_PRODUCTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ProductRoadMapConstants.UPDATE_PRODUCT_LAUNCH_DATE_SUCCESS: {
      const { productId, launchDate } = action.payload;

      return {
        ...state,
        products: arrayReplaceFirst(
          state.products,
          x => x.name === productId,
          product => ({ ...product, launchDate })
        ),
      };
    }
    case ProductsConstants.UPDATE_PRODUCT_SUCCESS: {
      const { changes, selectedProductName } = action.payload;

      return {
        ...state,
        products: arrayReplaceFirst(
          state.products,
          x => x.name === selectedProductName,
          product => ({
            ...product,
            ...changes,
          })
        ),
      };
    }
    default:
      return state;
  }
};
