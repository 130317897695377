const defaultRevision = '1.0.0';

const updateMajorVersion = (revision?: string) => {
  if (revision && revision.split('.').length === 3) {
    const revisionComponents = revision.split('.');
    const major = Number(revisionComponents[0]) + 1;
    if (major >= 0) {
      return `${major}.0.0`;
    }
    return revision;
  }
  return defaultRevision;
};

const updateMinorVersion = (revision?: string) => {
  if (revision && revision.split('.').length === 3) {
    const revisionComponents = revision.split('.');
    const minor = Number(revisionComponents[1]) + 1;
    if (minor >= 0) {
      return `${revisionComponents[0]}.${minor}.0`;
    }
    return revision;
  }
  return defaultRevision;
};

const updatePatchVersion = (revision?: string) => {
  if (revision && revision.split('.').length === 3) {
    const revisionComponents = revision.split('.');
    const patch = Number(revisionComponents[2]) + 1;
    if (patch >= 0) {
      return `${revisionComponents[0]}.${revisionComponents[1]}.${patch}`;
    }
    return revision;
  }
  return defaultRevision;
};

const increaseRevision = (revision?: string, upgradeType?: DTO.UpgradeType) => {
  switch (upgradeType) {
    case 'major':
      return updateMajorVersion(revision);
    case 'minor':
      return updateMinorVersion(revision);
    case 'patch':
      return updatePatchVersion(revision);
    default:
      return updateMinorVersion(revision);
  }
};

const compareRevision = (revision1: string, revision2: string) => {
  if (
    revision1 &&
    revision1.split('.').length === 3 &&
    revision2 &&
    revision2.split('.').length === 3
  ) {
    const major1 = Number(revision1.split('.')[0]);
    const major2 = Number(revision2.split('.')[0]);
    if (major1 !== major2) {
      return major1 > major2;
    }

    const minor1 = Number(revision1.split('.')[1]);
    const minor2 = Number(revision2.split('.')[1]);
    if (minor1 !== minor2) {
      return minor1 > minor2;
    }

    const patch1 = Number(revision1.split('.')[2]);
    const patch2 = Number(revision2.split('.')[2]);
    return patch1 > patch2;
  }

  return revision1 > revision2;
};

export { increaseRevision, compareRevision };
