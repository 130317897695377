import { ProductDocsConstant } from '../constants';
import {
  ProductDocsAction,
  ProductDocsThunkAction,
  ProductDocsThunkActionSync,
} from './types';
import { ProductService } from '../services';
import { AlertActions } from './alert.actions';
import { ApiError } from '../helpers';

const getProductDocs = (
  page: number,
  pageSize: number,
  productName: string,
  documentType: string,
  searchTerm: string
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.GET_PRODUCT_DOCS_REQUEST,
      payload: {
        productName,
        documentType,
      },
    });
    const { payload, status } = await ProductService.getProductDocs(
      page,
      pageSize,
      productName,
      documentType,
      searchTerm
    );
    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.GET_PRODUCT_DOCS_SUCCESS,
      payload: {
        total: payload.count,
        docs: payload.data,
        documentType,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.GET_PRODUCT_DOCS_FAILURE,
      payload: {
        error: msg,
        documentType,
      },
    });
  }
};

const uploadDocument = (
  documentType: string,
  productName: string,
  file: File,
  metadata: object
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({ type: ProductDocsConstant.ADD_DOCUMENT_UPLOAD_START });

    const { status, payload } = await ProductService.uploadProductDoc(
      documentType,
      productName,
      file,
      metadata,
      uploadProgress => {
        if (uploadProgress === 100) {
          return;
        }

        // 5% for server processing time
        dispatch({
          type: ProductDocsConstant.ADD_DOCUMENT_UPLOAD_PROGRESS,
          payload: {
            uploadProgress: Math.max(5, uploadProgress - 5),
          },
        });
      },
      xhrRef => {
        dispatch({
          type: ProductDocsConstant.ADD_DOCUMENT_UPLOAD_XHR_REF,
          payload: { xhrRef },
        });
      }
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.ADD_DOCUMENT_SUCCESS,
      payload: {
        documentType,
      },
    });
    payload.message && dispatch(AlertActions.success(payload.message));
    dispatch({
      type: ProductDocsConstant.ADD_DOCUMENT_RESET,
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.ADD_DOCUMENT_ERROR,
      payload: {
        error: msg,
      },
    });
  }
};

const deleteDoc = (
  productName: string,
  fileName: string,
  documentType: string
): ProductDocsThunkAction => async dispatch => {
  dispatch({
    type: ProductDocsConstant.DELETE_DOC_REQUEST,
    payload: {
      productName,
      fileName,
      documentType,
    },
  });

  try {
    const { status, payload } = await ProductService.deleteDocs(
      productName,
      fileName,
      documentType
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.DELETE_DOC_SUCCESS,
      payload: {
        productName,
        fileName,
        documentType,
      },
    });

    dispatch(
      AlertActions.success('ProductDocs.menu.delete.success', { fileName })
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.DELETE_DOC_FAILURE,
      payload: {
        productName,
        fileName,
        documentType,
        error: msg,
      },
    });
  }
};

const downloadDocument = (
  request: DTO.DownloadDocumentRequest,
  documentType: string
): ProductDocsThunkActionSync => (_, getState) => {
  const {
    auth: { userAuth },
  } = getState();
  if (!userAuth) {
    return;
  }

  const { id_token } = userAuth;

  window.location.href = ProductService.getDocumentDownloadUrl(
    request,
    documentType,
    id_token
  );
};

const resetAddDocument = (): ProductDocsAction => ({
  type: ProductDocsConstant.ADD_DOCUMENT_RESET,
});

const resetProductDocList = (type: string): ProductDocsAction => ({
  type: ProductDocsConstant.DOCUMENT_LIST_RESET,
  payload: { type },
});

const updateDocFavourite = (
  productName: string,
  fileName: string,
  documentType: string,
  favorite: boolean
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.UPDATE_DOC_FAVORITE_REQUEST,
    });

    const { status, payload } = await ProductService.markDocFavorite(
      productName,
      fileName,
      documentType,
      favorite
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.UPDATE_DOC_FAVORITE_SUCCESS,
      payload: {
        fileName,
        documentType,
        favorite,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.UPDATE_DOC_FAVORITE_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const createSection = (
  productName: string,
  documentType: string,
  position: number,
  fileTypes: string[]
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.CREATE_SECTION_REQUEST,
    });

    const { status, payload } = await ProductService.createSection(
      productName,
      documentType,
      position,
      fileTypes
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.CREATE_SECTION_SUCCESS,
      payload: {
        productName,
        documentType,
        position,
        fileTypes,
      },
    });

    dispatch(AlertActions.success('ProductEngines.section.create.success'));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.CREATE_SECTION_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const updateSection = (
  productName: string,
  oldDocumentType: string,
  newDocumentType: string,
  position: number,
  fileTypes: string[]
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.UPDATE_SECTION_REQUEST,
    });

    const { status, payload } = await ProductService.updateSection(
      productName,
      oldDocumentType,
      newDocumentType,
      position,
      fileTypes
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.UPDATE_SECTION_SUCCESS,
      payload: {
        productName,
        oldDocumentType,
        newDocumentType,
        position,
        fileTypes,
      },
    });
    dispatch(AlertActions.success('ProductEngines.section.update.success'));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.UPDATE_SECTION_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const deleteSection = (
  productName: string,
  documentType: string
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.DELETE_SECTION_REQUEST,
    });

    const { status, payload } = await ProductService.deleteSection(
      productName,
      documentType
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.DELETE_SECTION_SUCCESS,
      payload: {
        productName,
        documentType,
      },
    });
    dispatch(AlertActions.success('ProductEngines.section.delete.success'));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.DELETE_SECTION_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const downloadSwagger = (
  productName: string,
  fileName: string,
  documentType: string
): ProductDocsThunkAction => (_, getState) => {
  const {
    auth: { userAuth },
  } = getState();

  if (!userAuth) {
    return;
  }

  const { id_token } = userAuth;

  window.location.href = ProductService.getSwaggerDownloadUrl(
    productName,
    fileName,
    documentType,
    id_token
  );
};

const downloadCurl = (
  productName: string,
  fileName: string,
  documentType: string
): ProductDocsThunkAction => (_, getState) => {
  const {
    auth: { userAuth },
  } = getState();

  if (!userAuth) {
    return;
  }

  const { id_token } = userAuth;

  window.location.href = ProductService.getCurlDownloadUrl(
    productName,
    fileName,
    documentType,
    id_token
  );
};

const getDocumentMetadata = (
  productName: string,
  fileName: string,
  documentType: string
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.GET_PRODUCT_DOCUMENT_CONTENT_REQUEST,
    });

    const { status, payload } = await ProductService.getDocumentContent(
      productName,
      fileName,
      documentType
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }
    dispatch({
      type: ProductDocsConstant.GET_PRODUCT_DOCUMENT_CONTENT_SUCCESS,
      payload: {
        productName,
        fileName,
        documentType,
        metadata: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.GET_PRODUCT_DOCUMENT_CONTENT_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const updateDocumentMetadata = (
  productName: string,
  fileName: string,
  documentType: string,
  metadata: object
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.UPDATE_PRODUCT_DOCUMENT_METADATA_REQUEST,
    });

    const { status, payload } = await ProductService.updateDocumentMetadata(
      productName,
      fileName,
      documentType,
      metadata
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.UPDATE_PRODUCT_DOCUMENT_METADATA_SUCCESS,
    });

    dispatch(AlertActions.success('ProductDocument.message.update.success'));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.UPDATE_PRODUCT_DOCUMENT_METADATA_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const resetUpdateDocumentMetadata = (): ProductDocsThunkAction => dispatch => {
  dispatch({
    type: ProductDocsConstant.UPDATE_PRODUCT_DOCUMENT_METADATA_RESET,
  });
};

export const ProductDocsActions = {
  getProductDocs,
  uploadDocument,
  resetAddDocument,
  resetProductDocList,
  downloadDocument,
  deleteDoc,
  updateDocFavourite,
  createSection,
  updateSection,
  deleteSection,
  downloadSwagger,
  downloadCurl,
  getDocumentMetadata,
  updateDocumentMetadata,
  resetUpdateDocumentMetadata,
};
